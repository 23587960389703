import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {ProductDto} from "@Types/product";
import SizeSelector from "@Component/domains/product/ProductInfo/SizeSelector";
import React, {useState} from "react";
import {SizeCounter} from "@Component/domains/product/ProductInfo/sizeCounter";

import {checkUserInfo} from "@Service/user/UserService";
import styles from '@Css/product/product.module.scss'
import {insertCartApi} from "@Api/user/cart";
import ImageView from "@Component/common/Image/ImageView/ImageView";
import {ProductIcon} from "@Component/domains/product/ProductList/ProductIcon";
import Button from "@Component/common/Input/Button";
import {insertOrderApi} from "@Api/user/order";
import {useNavigate} from "react-router-dom";

export const ProductInfo = (props: {
    product: ProductDto.Response | undefined
}) => {
    const navigate = useNavigate()
    const modal = getModalInstance()
    const product = props.product
    const [itemDto, setItemDto] = useState({
        size: '',
        item: []
    })


    return (
        <div className={styles.header}
             onMouseOver={() => {
                 const pcSideBar = document.querySelector('.pc-side-bar') as HTMLElement
                 if (pcSideBar) {
                     pcSideBar.style.transform = 'translateX(100%)'
                 }
             }}
             onMouseOut={() => {
                 const pcSideBar = document.querySelector('.pc-side-bar') as HTMLElement
                 if (pcSideBar) {
                     pcSideBar.style.transform = 'translateX(0%)'

                 }
             }
             }>
            <ImageView imageUrl={product?.imageUrl || []} parentClass={styles.image}/>
            <div className={styles.info + ' product-info'}>
                <h2 className={styles.title}>{product?.name}</h2>
                <div className={styles.icon}>
                    <ProductIcon product={product} rating={true} />
                </div>
                <hr/>
                <table>
                    <tbody>
                    <tr>
                        <th>상품코드</th>
                        <td id={'code'}>{product?.productCode}</td>
                    </tr>
                    <tr>
                        <th>상품가격</th>
                        <td id={'price'} data-price={product?.price}>
                            {(product?.price || '').toLocaleString()} 원
                        </td>
                    </tr>
                    <tr>
                        <th>배송방법</th>
                        <td>택배</td>
                    </tr>
                    <tr>
                        <th>배송사</th>
                        <td>우체국 택배</td>
                    </tr>
                    <tr>
                        <th>배송비</th>
                        <td id={'delivery-fee'}>
                            <p>{product?.deliveryFee?.toLocaleString()} 원</p>
                            <p> <span style={{color:"red"}}>*</span> {product?.deliveryFeeThreshold?.toLocaleString()} 원 이상 구매시 무료</p>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <hr/>
                <h4>사이즈 선택</h4>
                <SizeSelector
                    data={itemDto}
                    setData={setItemDto}
                    productSize={product?.size}
                />
                <hr/>
                <SizeCounter data={itemDto} setData={setItemDto} product={product}

                />

                <div className={styles.action}>
                    <Button
                        name={'장바구니'}
                        onClick={() => {
                            insertCartApi(itemDto.item);
                        }}
                    />
                    <Button
                        name={'바로구매'}
                        black={true}
                        onClick={async () => {
                            if (!(await checkUserInfo(undefined, false))) {
                                modal?.confirm(
                                    () => {
                                        window.location.href = '/user/login'
                                    },
                                    '알림',
                                    '주문을 위해 로그인이 필요합니다.',
                                    '로그인 하시겠습니까?'
                                )

                            } else {
                                if (itemDto.item.length == 0) {
                                    modal?.alert(()=>{},'알림','옵션을 선택해주세요.')
                                    return
                                }
                                modal?.confirm(async () => {
                                    if ( await insertOrderApi(itemDto.item)) {
                                        navigate('/order/checkout')
                                    }
                                }, '확인', '상품을 구매하시겠습니까?')
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

