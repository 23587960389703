import {deleteWishApi, getWishListApi, insertWishApi,} from '@Api/user/wish'

export const addWish = async (event: React.MouseEvent, productId: string) => {
    const target = event.target as HTMLElement

    if (target.classList.contains('icon-heart-empty')) {
        if ((await insertWishApi(productId)) == '') {
            const element = document.querySelectorAll(
                `.${target.classList[0]}`
            ) as NodeListOf<HTMLElement>

            element.forEach((el) => {
                el.classList.remove('icon-heart-empty')
                el.classList.add('icon-heart')
            })
        }
    } else {
        if ((await deleteWishApi(productId)) == '') {
            const element = document.querySelectorAll(
                `.${target.classList[0]}`
            ) as NodeListOf<HTMLElement>

            element.forEach((el) => {
                el.classList.remove('icon-heart')
                el.classList.add('icon-heart-empty')
            })
        }
    }
}

export const setWishicon = async () => {
    const wishList = (await getWishListApi()) || []

    wishList.forEach((wish) => {
        const element = document.querySelectorAll(
            `.product-wish-check-${wish.productId}`
        ) as NodeListOf<HTMLElement>

        element.forEach((el) => {
            el.classList.remove('icon-heart-empty')
            el.classList.add('icon-heart')
        })
    })
}
