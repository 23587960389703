import './MypageAccount.css'
import {UserDto} from "@Types/user";
import {OrderDto} from "@Types/order";
import {useEffect, useState} from "react";
import WishView from "@Component/domains/product/Wish";
import {ViewProduct} from "@Component/domains/product/ViewProduct";
import {checkUserInfo, kakaoLogin} from "@Service/user/UserService";
import Button from "@Component/common/Input/Button";
import {userLoginApi} from "@Api/user/account";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import modal from "@Component/common/Modal/Modal";
// import {OrderCountDTO} from "@Types/order/orderDto";

 const AccountInfo = () => {
    const [orderCount, setOrderCount] = useState<OrderDto.Count>()
    const [userInfo, setUserInfo] = useState<UserDto.Info>()
     const queryParams = new URLSearchParams(location.search);
     const code = queryParams.get('code');

    const fetchData = async () => {
        // setOrderCount(await getOrderCountService())
        checkUserInfo(setUserInfo,true)
    }

    useEffect(() => {
        fetchData()
    }, [])

     useEffect(() => {
         const modal = getModalInstance();
         if(code=='409'){
             modal?.alert(()=>{},'알림','계정이 연동되어있습니다.','이미 연동된 카카오 계정입니다.')
         }
     }, [code]);

    return (
        <div className={'mypage-account'}>
            <h3>유저 정보</h3>
            <UserInfoView userInfo={userInfo}  isMypage={true}/>
            <h3>진행 현황</h3>
            <div className="order-dashboard">
                <div>
                    <a>
                        <p>주문완료</p>
                        <p>({orderCount?.completeOrderCount || 0})</p>
                    </a>
                </div>
                <div>
                    <i className="icon-right-open"></i>
                </div>
                <div>
                    <a>
                        <p>준비중</p>
                        <p>({orderCount?.preparationCount || 0})</p>
                    </a>
                </div>
                <div>
                    <i className="icon-right-open"></i>
                </div>
                <div>
                    <a>
                        <p>배송중</p>
                        <p>({orderCount?.inDeliveryCount || 0})</p>
                    </a>
                </div>
                <div>
                    <i className="icon-right-open"></i>
                </div>
                <div>
                    <a>
                        <p>배송완료</p>
                        <p>({orderCount?.deliveredCount || 0})</p>
                    </a>
                </div>
                <div>
                    <p style={{ fontWeight: '1000' }}>/</p>
                </div>
                <div>
                    <a>
                        <p>교환/반품</p>
                        <p id="exchange-refund">
                            ({orderCount?.exchangeOrRefundCount || 0})
                        </p>
                    </a>
                </div>
            </div>
            <WishView />
            <ViewProduct />
        </div>
    )
}

export const UserInfoView = (props: {
    userInfo?: UserDto.Info
    isMypage?: boolean
}) => {
    const modal= getModalInstance()

    return (

        <div className="user-info">
            <div>
                <div>
                    <span>이름 :</span> {props.userInfo?.userName}
                </div>
                <div>
                    <span>전화번호 :</span> {props.userInfo?.phoneNumber}
                </div>
                <div>
                    <span>이메일 :</span> {props.userInfo?.email}
                </div>
            </div>

            {props.isMypage && (
                <div>
                    <Button name={'카카오 계정연동'} onClick={()=>{
                        modal?.confirm(()=>{kakaoLogin(true)},'확인','계정연동시 계정정보가 변경됩니다.','기존 아이디로 로그인이 불가해집니다<br/>연동하시겠습니까?')
                        }}/>
                    <Button name={'회원정보수정'} onClick={()=>{}} />
                    <Button name={'회원탈퇴'} onClick={()=>{}}/>
                </div>
            )}
        </div>
    )
}

export default AccountInfo