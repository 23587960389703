export const formatDate = (dateString?: string, format?: string): string => {

    const date = new Date(dateString || '');

    const options: Record<string, string> = {
        yyyy: date.getFullYear().toString(),
        MM: ('0' + (date.getMonth() + 1)).slice(-2),
        dd: ('0' + date.getDate()).slice(-2),
        HH: ('0' + date.getHours()).slice(-2),
        mm: ('0' + date.getMinutes()).slice(-2),
        ss: ('0' + date.getSeconds()).slice(-2),
    };

    const dateFormat = format || 'yyyy/MM/dd HH:mm:ss'
    return dateFormat.replace(/yyyy|MM|dd|HH|mm|ss/g, (match) => options[match]);
}