import {ValidationOption} from "@Util/formUtils";
import {checkDuplicateApi} from "@Api/user/account";
import {bankOptions} from "@Types/order";


export const paymentValid: ValidationOption[] = [
    {
        name: 'accountCode',
        baseMessage: ' ',
        nullAbleMessage:  '결제 은행을 선택해주세요',
    },
    {
        name: 'accountHolder',
        baseMessage: '예금주를',
    },

    {
        name: 'accountNumber',
        baseMessage: '계좌번호를',
    },
];