import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {deleteRequest, getRequest, postRequest} from "@Api/Api";
import {BoardDto} from "@Types/board";
import {getUserInfo} from "@Api/user/account";
import {formatDate} from "@Util/dateUtile";

export const saveBoardApi = async (formData: FormData) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        modal?.closeModal()
                    },
                    '알림',
                    '게시글이 저장되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {
            401: () => {
                modal?.closeModal()
                getUserInfo(true)
                modal?.alert(()=>{},'알림','작성을위해 로그인이 필요합니다.')
            }
        }

        return await postRequest('/board', formData, httpSuccess, httpAlert, {
            headers: {
                'Content-Type': 'multipart/form-data', // 멀티파트 요청의 Content-Type 지정
            },
        })
    } catch
        (e) {
        console.log(e)
    }
}


export const deleteBoardApi = async (boardId: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {
                        modal?.closeModal()
                    },
                    '알림',
                    '게시글이 삭제되었습니다.'
                )
            },
        }
        return await deleteRequest(
            '/board/' + boardId,
            httpSuccess
        )
    } catch (e) {
        console.log(e)
    }
}

export const getBoardListApi = async (searchQuery: BoardDto.Search) => {
    try {
        searchQuery.startDate = formatDate(searchQuery?.startDate?.toString())
        searchQuery.endDate = formatDate(searchQuery?.endDate?.toString())
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<BoardDto.Page>(
            '/board/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getBoardApi = async (boardId: string, isAdmin?: boolean) => {
    const modal = getModalInstance()
    try {

        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {
            410: () => {
                modal!.alert(() => {
                }, '알림', '삭제된 게시물입니다.')
            },
        }

        return await getRequest<BoardDto.Response>(
            (isAdmin ? '/admin/board/' : '/board/') + boardId,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}