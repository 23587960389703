import React, {useEffect, useRef, useState} from 'react'
import {onErrorImg} from '@Util/imageUtils'
import styles from './index.module.scss'
import {disableScroll, enableScroll} from '@Util/viewUtils'

const ImageView = (props: {
    parentClass?: string
    imageUrl: string[]
}) => {
    const [currentImageSrc, setCurrentImageSrc] = useState('')
    const [offsetX, setOffsetX] = useState(0)
    const imageListRef = useRef<HTMLDivElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const startX = useRef(0)
    const initialOffset = useRef(0)
    const imageUrl = props.imageUrl

    const [showMagnifier, setShowMagnifier] = useState(false)
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })
    const [zoomLevel, setZoomLevel] = useState(10) // 배율 설정

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!showMagnifier) return

        const { top, left, width, height } =
            e.currentTarget.getBoundingClientRect()
        const scrollTop = document.documentElement.scrollTop
        const scrollLeft = document.documentElement.scrollLeft

        const x = ((e.pageX - left - scrollLeft) / width) * 100
        const y = ((e.pageY - top - scrollTop) / height) * 100

        setMousePosition({ x, y })
    }

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!showMagnifier) return

        const { top, left, width, height } =
            e.currentTarget.getBoundingClientRect()
        const scrollTop = document.documentElement.scrollTop
        const scrollLeft = document.documentElement.scrollLeft

        // 터치 이벤트에서 첫 번째 터치 포인트 가져오기
        const touch = e.touches[0]

        const x = ((touch.pageX - left - scrollLeft) / width) * 100
        const y = ((touch.pageY - top - scrollTop) / height) * 100

        setMousePosition({ x, y })
    }

    const handleMagnifierClick = () => {
        setShowMagnifier(!showMagnifier)
    }

    const handleImageClick = (src: string) => {
        // 현재 스크롤 위치를 저장
        const previousScrollY = window.scrollY

        setCurrentImageSrc(src) // 이미지 변경

        // 스크롤 위치를 이전 위치로 이동
        window.scrollTo(0, previousScrollY)
    }

    const handleResize = () => {
        updateOffsetX(offsetX)
    }

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        const touchStartX = e.touches[0].clientX
        const initialOffsetX = offsetX

        const handleTouchMove = (moveEvent: TouchEvent) => {
            const touchMoveX = moveEvent.touches[0].clientX
            const deltaX = touchStartX - touchMoveX
            updateOffsetX(initialOffsetX - deltaX)
        }

        const handleTouchEnd = () => {
            document.removeEventListener('touchmove', handleTouchMove)
            document.removeEventListener('touchend', handleTouchEnd)
        }
        document.addEventListener('touchmove', handleTouchMove, {
            passive: false,
        })

        document.addEventListener('touchend', handleTouchEnd)
        disableScroll()
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        startX.current = e.pageX
        initialOffset.current = offsetX

        const handleMouseMove = (e: MouseEvent) => {
            e.preventDefault()
            const x = e.pageX
            const newOffset = initialOffset.current + (x - startX.current)

            updateOffsetX(newOffset)
        }

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }

        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const updateOffsetX = (newOffset: number) => {
        if (!imageListRef.current || !wrapperRef.current) return

        const imageCount = imageUrl.length
        newOffset = Math.min(0, newOffset)
        const maxOffset =
            wrapperRef.current.offsetWidth -
            (imageCount * 80 + (imageCount - 1) * 10)
        newOffset = Math.max(newOffset, maxOffset)

        setOffsetX(newOffset)

        // 각 이미지 요소에 transform 적용
        Array.from(imageListRef.current.children).forEach((child) => {
            ;(
                child as HTMLElement
            ).style.transform = `translateX(${newOffset}px)`
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [offsetX])

    useEffect(() => {
        setCurrentImageSrc(process.env.BASE_URL + imageUrl[0])
    }, [imageUrl])

    return (
        <div className={props.parentClass + ' ' + styles.imageView}>
            <div className={styles.currentImage}>
                <img
                    src={currentImageSrc}
                    draggable="false"
                    alt=""
                    onError={(e) => onErrorImg(e)}
                    onMouseMove={handleMouseMove}
                    onTouchMove={handleTouchMove}
                    onTouchStart={disableScroll}
                    onTouchEnd={enableScroll}
                />
                <div className={styles.magnifierIcon} onClick={handleMagnifierClick}>
                    <i className={'icon-search'}></i>
                </div>
                {showMagnifier && (
                    <div
                        className={styles.magnifierWindow}
                        style={{
                            backgroundImage: `url(${currentImageSrc})`,
                            backgroundPosition: `${mousePosition.x}% ${mousePosition.y}%`,
                            backgroundSize: `${zoomLevel * 100}%`,
                        }}
                    />
                )}
            </div>
            <div className={styles.imageSelect}>
                <div
                    className={styles.wrapper}
                    ref={wrapperRef}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={enableScroll}
                    onMouseDown={handleMouseDown}
                    style={{ overflow: 'hidden' }}
                >
                    <div
                        className={styles.list}
                        ref={imageListRef}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {props.imageUrl.map((url, index) => (
                            <div
                                className={'image-' + index}
                                key={index}
                                style={{
                                    transform: `translateX(${offsetX}px)`,
                                }}
                            >
                                <img
                                    src={process.env.BASE_URL + url}
                                    onError={(e) => onErrorImg(e)}
                                    draggable="false"
                                    onClick={() =>
                                        handleImageClick(
                                            process.env.BASE_URL + url
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export  default  ImageView
