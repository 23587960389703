import { FormFieldProps } from 'components/layout/FormField'

export const resetForm: FormFieldProps[] = [
    {
        label: '아이디',
        placeholder: 'ID',
        inputType: 'text',
        name: 'loginId',
    },
    {
        label: '이메일',
        placeholder: 'EMAIL',
        inputType: 'password',
        name: 'password',
    },
    {
        label: '비밀번호',
        placeholder: 'PASSWORD',
        inputType: 'password',
        name: 'password',
    },
    {
        label: '비밀번호 확인',
        placeholder: 'PASSWORD REENTER',
        inputType: 'password',
        name: 'password',
    },
    {
        label: '인증 코드',
        placeholder: 'AUTH TOKEN',
        inputType: 'password',
        name: 'password',
    },
]
