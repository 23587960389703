import {CartDto} from "@Types/cart";
import {OrderDto} from "@Types/order";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {getRequest, postRequest} from "@Api/Api";
import {getUserInfo} from "@Api/user/account";


export const deleteCartApi = async (carts: CartDto.Delete[]) => {
    const modal = getModalInstance()
    try {
        return await postRequest('/cart/del', carts)
    } catch (e) {
        console.log(e)
    }
}

export const getCartListApi = async (): Promise<CartDto.TransformedCart[]> => {
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {
            401:()=>{
                getUserInfo(true)
            }
        }

        const cartList = await getRequest<CartDto.Response[]>(
            '/cart/list',
            httpSuccess,
            httpAlert,
            {}
        )

        if (!cartList) {
            return []
        }

        const transformedCartList: CartDto.TransformedCart[] = []

        cartList.forEach((cartItem) => {
            if (
                !transformedCartList.some(
                    (item) =>
                        item.product.productId === cartItem.product?.productId
                )
            ) {
                transformedCartList.push(<CartDto.TransformedCart>{
                    product: cartItem?.product,
                    sizes: [],
                })
            }

            transformedCartList
                .find(
                    (item) =>
                        item.product.productId === cartItem.product?.productId
                )
                ?.sizes.push({
                product:cartItem.product,
                size: cartItem?.size!,
                count: Number(cartItem?.count)
            })
        })

        return transformedCartList // 변환된 카트 정보 배열을 반환합니다.
    } catch (e) {
        console.log(e)
        return [] // 에러 발생 시 빈 배열을 반환합니다.
    }
}

export const insertCartApi = async (cartItem: OrderDto.Item[], alertDisable?: boolean) => {
    const modal = getModalInstance()

    if (cartItem.length == 0) {
        modal?.alert(() => {
        }, '알림', '사이즈를 선택해주세요')
        return
    }

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                if (!alertDisable) {
                    modal!.confirm(
                        () => {
                            window.location.href = '/order/cart'
                        },

                        '알림',
                        '장바구니에 추가되었습니다.',
                        '장바구니로 이동하시겠습니까?'
                    )
                }
            },
        }
        const httpAlert: HttpAlert = {
            401: () => {
                modal!.confirm(
                    () => {
                        window.location.href = '/user/login'
                    },
                    '알림',
                    '로그인이 필요합니다.',
                    '로그인 페이지로 이동하시겠습니까?'
                )
            },
        }

        await postRequest('/cart', cartItem, httpSuccess, httpAlert)
        return true
    } catch (e) {
        console.log(e)
        return false
    }

}
