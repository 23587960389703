import React, {useEffect, useState} from "react";
import {getFileMap} from "@Util/imageUtils";
// import {insertBoard, updateBoard} from "@Service/board/BoardService";
import ImageDrop from "@Component/common/Image/ImageDrop/ImageDrop";
import {BoardDto, mapResponseToSave,BoardCategory,createBoardSaveDto} from "@Types/board";

import styles from "@Css/form.module.scss";
import MainFrame, {ButtonOption} from "@Component/layout/MainFrame";
import {isValid} from "@Util/formUtils";
import {inquiryValid, reviewValid} from "@Component/domains/board/Edit/valid";
import {saveBoard} from "@Service/board";
import {getBoardApi} from "@Api/user/board";
import {Editor} from "@Component/common/Input/Editor/Editor";
import CheckBox from "@Component/common/Input/CheckBox";
import FormField, {FormFieldProps} from "@Component/layout/FormField";

export const InquiryEditor = (props: {
    productId?: string
    boardId?: string
}) => {
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())
    const [saveDto, setSaveDto] = useState<BoardDto.Save>(createBoardSaveDto(BoardCategory.Inquiry, props.productId))
    const [defaultData, setDefaultData] = useState<string>()


    const inquiryForm: FormFieldProps[] = [
        {
            label: '문의제목',
            rightLabel: (<CheckBox label={'비공개'} data={saveDto} setData={setSaveDto} name={'isHidden'}/>)
            ,
            placeholder: 'REVIEW TITLE',
            inputType: 'text',
            name: 'title'
        },
    ];


    const actionButton: ButtonOption[] = [
        {
            name: props.boardId ? '수정' : '저장',
            onClick: async () => {
                if (await isValid(saveDto, inquiryValid, true)) {
                    saveBoard(saveDto, fileMap)
                }
            }
        }
    ]
    const fetchData = async (boardId?: string) => {
        if (props.boardId) {
            const response = await getBoardApi(props.boardId)
            if(response){
                setSaveDto(mapResponseToSave(response))
                setFileMap(await getFileMap(response?.imageUrl || []));
                setDefaultData(response.content)
            }
        }
    }

    useEffect(() => {
        fetchData(props.boardId)
    }, [props.boardId])

    return (
        <MainFrame className={styles.form} maxWidth={900} title={props.boardId ? '문의 수정' :'문의 작성'} marginZero={true} width={'96%'}
                   action={actionButton}>
            <FormField formFieldList={inquiryForm} data={saveDto} setData={setSaveDto} validOption={reviewValid}/>
            <h3>사진 첨부</h3>
            <ImageDrop
                fileMap={fileMap}
                setFileMap={setFileMap}
            />
            <h3>문의 내용</h3>
            <Editor name={'content'}
                    data={saveDto}
                    setData={setSaveDto}
                    userSet={true}
                    height={300}
                    defaultData={defaultData}
            />
        </MainFrame>
    )
}
