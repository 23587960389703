// import {getModalInstance} from "@Component/common/Modal/ModalProvider";
// import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
// import {getRequest, putRequest} from "@Api/Api";
// import {OrderDTO, OrderListDTO, OrderSearchDTO, UpdateOrderDTO} from "@Types/order/orderDto";
//
// export const getAdminOrderService = async (orderCode?: string) => {
//     const modal = getModalInstance()
//     try {
//         const httpSuccess: HttpSuccess = {}
//         const httpAlert: HttpAlert = {}
//
//         return await getRequest<OrderDTO>(
//             `/admin/order/${orderCode || ''}`,
//             httpSuccess,
//             httpAlert,
//             {}
//         )
//     } catch (e) {
//         console.log(e)
//     }
// }
//
// export const getOrderListService = async (searchQuery: OrderSearchDTO) => {
//     const modal = getModalInstance()
//     try {
//         const httpSuccess: HttpSuccess = {}
//         const httpAlert: HttpAlert = {}
//         const config = {
//             params: searchQuery,
//         }
//
//         return await getRequest<OrderListDTO>(
//             searchQuery.admin ? '/admin/order/list' : '/order/list',
//             httpSuccess,
//             httpAlert,
//             config
//         )
//     } catch (e) {
//         console.log(e)
//     }
// }
//
// export const adminUpdateOrderService = async (order: UpdateOrderDTO) => {
//     const modal = getModalInstance()
//
//     try {
//         const httpSuccess: HttpSuccess = {}
//         const httpAlert: HttpAlert = {}
//         await putRequest('/admin/order', order, httpSuccess, httpAlert, {})
//
//         return true
//     } catch (e) {
//         console.log(e)
//         return false
//     }
// }

import { OrderDto } from '@Types/order'
import { getRequest, postRequest } from '@Api/Api'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { formatDate } from '@Util/dateUtile'
import { HttpAlert, HttpSuccess } from '@Types/cmm/HttpCallBack'

export const getAdminOrderListApi = async (searchQuery: OrderDto.Search) => {
    const modal = getModalInstance()
    try {
        searchQuery.startDate = formatDate(searchQuery?.startDate?.toString())
        searchQuery.endDate = formatDate(searchQuery?.endDate?.toString())
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}
        const config = {
            params: searchQuery,
        }

        return await getRequest<OrderDto.Page>(
            '/admin/order/list',
            httpSuccess,
            httpAlert,
            config
        )
    } catch (e) {
        console.log(e)
    }
}

export const getAdminOrderApi = async (orderCode?: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {}
        const httpAlert: HttpAlert = {}

        return await getRequest<OrderDto.Response>(
            `/admin/order/${orderCode || ''}`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}
export const insertOrderApi = async (order: OrderDto.AdminInsert) => {
    const temp = order
    temp.paymentAmount = temp?.paymentAmount!.replaceAll(',', '')
    if (temp?.paymentType != 'ACCOUNT') {
        temp.accountCode = ''
        temp.accountNumber = ''
        temp.accountHolder = ''
    }
    temp.paymentType =
        temp.paymentType == 'ACCOUNT' ? '계좌이체' : '신용카드/간편결제'
    try {
        await postRequest('/admin/order', order)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}
