import { SearchField } from '@Component/layout/SearchField'
import {BoardCategory} from "@Types/board";

export const boardSearch= (category ?: number): SearchField[][] => [
    [
        {
            type: 'date',
            label: '조회 기간',
        },
    ],
    [
        {
            type: 'select',
            name: 'boardState',
            label: '게시글 상태',
            options: [
                { value: '', label: '- 일반 조회 -' },
                { value: 'delete', label: '삭제 게시글' },
                ...(category === BoardCategory.Notice ? [] : [{ value: 'answer', label: '미답변 게시글' }] ),
            ],
        },
    ],
    [
        {
            type: 'text',
            name: 'title',
            label: '제목',
            placeholder: '제목을 입력해주세요.',
        },
        {
            type: 'text',
            name: 'content',
            label: '내용',
            placeholder: '내용을 입력해주세요.',
        },
    ],
]
