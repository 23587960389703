import React from "react";

export interface  FooterOption {
    name : string
    onClick : ()=>void
}
interface FooterProps {
    options: FooterOption[]
}

const Footer: React.FC<FooterProps> = ({options}) => {
    return (
        <tr>
            <th colSpan={100}>
                {options?.map((item, index) => (
                    <input
                        key={index}
                        onClick={item.onClick}
                        type={'button'}
                        value={item.name}
                    />
                ))}
            </th>
        </tr>
    );
};

export default Footer;
