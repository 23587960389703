import React, {useEffect} from 'react'
import InquiryList from "@Component/domains/board/List/InquiryList";
import MainFrame from "@Component/layout/MainFrame";

function InquiryPage() {
    useEffect(() => {
        document.title = 'BROSPO 상품문의'
    }, [])
    return (
        <MainFrame title={'상품문의'} documentTitle={'상품문의'} maxWidth={1000}>
            <InquiryList write={true}/>
        </MainFrame>
    )
}

export default InquiryPage
