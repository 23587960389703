import React, {useEffect, useState} from "react";
import {CategoryList} from "@Types/category/categoryDto";
import {
    deletePmCategory,
    deleteSdCategory,
    insertPmCategory,
    insertSdCategory,
    updateCategoryOrder,
    updatePmCategory,
    updateSdCategory
} from "@Api/admin/category";
import styles from './index.module.scss'
import MainFrame from "@Component/layout/MainFrame";
import {getCategoryList} from "@Api/cmm/CmmService";

const AdminProductCategoryPage = () => {
    const [categoryList, setCategoryList] = useState<CategoryList>({})

    const fetchData = async () => {
        setCategoryList(await getCategoryList())
    }

    useEffect(() => {
        fetchData()

    }, []);
    return (
        <MainFrame className={styles.categoryEdit} title={'카테고리 수정'} documentTitle={'카테고리 수정'}>
            <div className={styles.categoryEditForm}>
                {Object.keys(categoryList).map((primaryCategory, index) => (
                    <div className={styles.category} key={index}>
                        <div className={styles.primaryCategory}>
                            <p>
                                {primaryCategory}
                            </p>
                            <i className={'icon-pencil-1'} onClick={
                                () => {
                                    updatePmCategory(primaryCategory, setCategoryList);
                                }}/>


                            <i className={'icon-trash'} onClick={
                                () => {
                                    deletePmCategory(primaryCategory, setCategoryList);
                                }}/>
                        </div>
                        {categoryList[primaryCategory].map(
                            (category, index) => (
                                <div
                                    className={styles.secondaryCategory}
                                    key={index}
                                >
                                    <p>{category.secondaryName}</p>
                                    <i className={'icon-menu'} onClick={
                                        () => {
                                            updateCategoryOrder(category, setCategoryList);
                                        }}/>
                                    <i className={'icon-pencil-1'}
                                       onClick={
                                           () => {
                                               updateSdCategory(category.categoryId,setCategoryList)
                                           }}/>

                                    <i className={'icon-trash'}
                                       onClick={
                                           () => {
                                               deleteSdCategory(category.categoryId, setCategoryList)
                                           }}/>
                                </div>
                            )
                        )}

                        <i className={'icon-plus'}
                           onClick={
                               () => {
                                   insertSdCategory(primaryCategory, setCategoryList)
                               }}/>
                    </div>
                ))}
                <i className={'icon-plus'}
                   onClick={
                       () => {
                           insertPmCategory(setCategoryList)
                       }}>
                    카테고리 추가
                </i>
            </div>
        </MainFrame>
    )
}

export default AdminProductCategoryPage;