import {ValidationOption} from "@Util/formUtils";

export const reviewValid: ValidationOption[] = [
    {
        name: 'title',
        baseMessage: '한줄평을',
        disableSuccessMessage:true,
    },
    {
        name: 'reviewRating',
        baseMessage: '상품평점',
        nullAbleMessage : '상품평점을 선택해주세요.'
    },
    {
        name: 'content',
        baseMessage: '구매후기를'
    },
];

export const inquiryValid: ValidationOption[] = [
    {
        name: 'title',
        baseMessage: '문의제목을',
        disableSuccessMessage:true,
    },
    {
        name: 'content',
        baseMessage: '문의내용을'
    },
];


export const noticeValid: ValidationOption[] = [
    {
        name: 'title',
        baseMessage: '한줄평을',
        disableSuccessMessage:true,
    },
    {
        name: 'content',
        baseMessage: '공지내용을'
    },
];