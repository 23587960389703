import {CategoryDto} from './categoryDto'; // 적절한 경로로 수정하세요

// 기본 카테고리 응답 DTO 생성
export const createCategoryResponseDto = (): CategoryDto.Response => ({
    categoryId: '',
    primaryName: '',
    secondaryName: '',
    menuOrderPm: 0,
    menuOrderSd: 0
});

// 기본 카테고리 추가 요청 DTO 생성
export const createCategoryInsertDto = (): CategoryDto.Insert => ({
    primaryName: '',
    secondaryName: ''
});

// 기본 2차 카테고리 업데이트 DTO 생성
export const createCategoryUpdateSdDto = (): CategoryDto.UpdateSd => ({
    categoryId: '',
    secondaryName: ''
});

// 기본 1차 카테고리 업데이트 DTO 생성
export const createCategoryUpdatePmDto = (): CategoryDto.UpdatePm => ({
    afterName: '',
    beforeName: ''
});
