import styles from './index.module.scss'

interface Props {
    name: string
    onClick: (e: any) => void
    black?: boolean,
    extraClass?: string
}

const Button: React.FC<Props> = ({name, onClick, black, extraClass}) => {

    return <input className={(black ? styles.blackButton : styles.whiteButton) + ' ' + extraClass} type={'button'}
                  onClick={(e) => {
                      onClick(e)
                  }} value={name}/>
}

export default Button