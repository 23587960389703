import React, {ReactNode, useEffect} from 'react';
import styles from './index.module.scss'
import Button from "@Component/common/Input/Button";

export interface ButtonOption {
    name: string
    onClick: () => void
}

interface MainFrameProps {
    title?: React.ReactNode;
    className?: string;
    maxWidth?: number;
    width?: string
    children?: ReactNode;
    documentTitle?: string;
    marginZero?: boolean;
    id?: string;
    action?: ButtonOption []
    hideSiteName?: boolean
}

const MainFrame: React.FC<MainFrameProps> = ({
                                                 title,
                                                 className = '',
                                                 maxWidth = 1200,
                                                 width,
                                                 children,
                                                 documentTitle = '',
                                                 marginZero,
                                                 id,
                                                 action,
                                                 hideSiteName
                                             }) => {
    const containerStyle: React.CSSProperties = {
        maxWidth: `${maxWidth}px`,
        margin: marginZero ? '0 auto' : undefined,
        width: width
    };

    useEffect(() => {
        document.title = `${process.env.SITE_NAME} ${documentTitle}`
    }, [documentTitle]);

    return (
        <div className={`${styles.content} ${className}`} style={containerStyle} id={id}>
            {title &&
                <h3 className={styles.title} style={{margin: marginZero ? '0 auto' : undefined}}>
                    {hideSiteName ? title :(<>{process.env.SITE_NAME}<span>{title}</span></>)}
                    <div className={styles.buttonArea}>
                        {action?.map((option, index) =>
                            <Button key={index} name={option.name} onClick={option.onClick}/>)}
                    </div>
                </h3>}
            {children}
        </div>
    );
};

export default MainFrame;
