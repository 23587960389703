import {FormFieldProps} from "components/layout/FormField";
import {setEmail, setNumber, setPhone} from "@Util/formUtils";


export const formList: FormFieldProps[] = [
    {
        label: '아이디',
        placeholder: 'LOGIN ID',
        inputType: 'text',
        name: 'loginId'
    },
    {
        label: '비밀번호',
        placeholder: 'PASSWORD',
        inputType: 'password',
        name: 'password'

    },
    {
        label: '비밀번호 확인',
        placeholder: 'PASSWORD RE ENTER',
        inputType: 'password',
        name: 'passwordReEnter'
    },
    {
        label: '이름',
        placeholder: 'USER NAME',
        inputType: 'text',
        name: 'userName'
    },
    {
        label: '전화번호',
        placeholder: 'PHONE NUMBER',
        inputType: 'text',
        name: 'phoneNumber',
        onChange: event => setPhone(event)
    },
    {
        label: '이메일',
        placeholder: 'EMAIL',
        inputType: 'email',
        name: 'email',
        onChange: event => setEmail(event)
    },

];
