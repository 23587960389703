import React, { useEffect, useState } from 'react'
import Item from '@Component/layout/ListField/Item'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import ListTemplate, {
    RowOption,
} from '@Component/layout/ListField/ListTemplate'
import Header from '@Component/layout/ListField/Header'
import Footer, { FooterOption } from '@Component/layout/ListField/Footer'
import { PageBar } from '@Component/common/PageBar'
import { privacyName } from '@Util/stringUtils'
import { BoardDto, BoardCategory, createBoardSearchDto } from '@Types/board'
import { InquiryEditor } from '@Component/domains/board/Edit/InquiryEditor'
import { useNavigate } from 'react-router-dom'
import { getUserInfo } from '@Api/user/account'
import { getBoardListApi } from '@Api/user/board'
import { UserDto } from '@Types/user'
import { InquiryView } from '@Component/domains/board/View/InquiryView'
import { formatDate } from '@Util/dateUtile'
import SearchField, { ActionField } from '@Component/layout/SearchField'
import { boardSearch } from './searchFeild'
import {getAdminBoardListApi} from "@Api/admin/board";

interface Props {
    productId?: string
    isAdmin?: boolean
    moreView?: boolean
    write?: boolean
    noAnswer?: boolean
    userId?: string | number
}

const InquiryList: React.FC<Props> = ({
    productId,
    isAdmin,
    moreView,
    write,
    noAnswer,
    userId,
}) => {
    const navigate = useNavigate()
    const modal = getModalInstance()
    const [search, setSearch] = useState<BoardDto.Search>(createBoardSearchDto(BoardCategory.Inquiry))
    const [boardList, setBoardList] = useState<BoardDto.Response[]>([])
    const [page, setPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [rowSize, setRowSize] = useState<number>(10)
    const [userInfo, setUserInfo] = useState<UserDto.Info>()

    const headerOptions = [
        { name: '번호', rowCount: 1, width: '10%' },
        { name: '제목', rowCount: 1, width: '50%' },
        { name: '작성자', rowCount: 1, width: '20%' },
        { name: '문의 날짜', rowCount: 1, hidden: true, width: '20%' },
    ]

    const rowOptions: RowOption[] =
        boardList.length !== 0
            ? boardList.map((board, index) => ({
                  rowList: [
                      <Item
                          key={`no-${index}`}
                          content={totalCount - (page - 1) * rowSize - index}
                          colSpan={1}
                      />,
                      <Item
                          key={`title-${index}`}
                          content={
                              <div style={{ textAlign: 'left' }}>
                                  {board.state === '2' &&
                                  userInfo?.role != 'ADMIN' &&
                                  board.user?.userId != userInfo?.userId ? (
                                      <>
                                          비공개글 입니다{' '}
                                          <i className="icon-lock"></i>
                                      </>
                                  ) : (
                                      board.title
                                  )}
                              </div>
                          }
                          colSpan={1}
                      />,
                      <Item
                          key={`user-${index}`}
                          content={
                              isAdmin
                                  ? board.user?.userName
                                  : privacyName(board.user?.userName || '')
                          }
                          colSpan={1}
                      />,
                      <Item
                          key={`date-${index}`}
                          content={formatDate(board.saveDate, 'yyyy/MM/dd')}
                          colSpan={1}
                          hidden={true}
                      />,
                  ],
                  onClick: () => {
                      modal?.popup(
                          <InquiryView boardId={board.boardId} />,
                          '800px',
                          () => {
                              fetchData()
                          }
                      )
                  },
              }))
            : [
                  {
                      rowList: [
                          <Item
                              key="no-reviews"
                              content={
                                  <div className={'empty-view'}>
                                      문의가 존재하지 않습니다.
                                  </div>
                              }
                              colSpan={4}
                          />,
                      ],
                  },
              ]

    const footerOptions: FooterOption[] = [
        ...(moreView
            ? [
                  {
                      name: '모두보기',
                      onClick: () => navigate('/board/inquiry'),
                  },
              ]
            : []),
        ...(write
            ? [
                  {
                      name: '작성하기',
                      onClick: () => handleWrite(),
                  },
              ]
            : []),
    ].filter(Boolean) as FooterOption[]

    const actionFieldList: ActionField[] = [
        {
            label: '검색',
            onClick: () => {  fetchData()},
        },
    ]

    const handleWrite = async () => {
        if (await getUserInfo(true)) {
            modal?.popup(
                <InquiryEditor productId={productId!} />,
                '800px',
                () => {
                    fetchData()
                }
            )
        } else {
            modal?.confirm(
                () => {
                    navigate('/user/login')
                },
                '알림',
                '작성을 위해 로그인이 필요합니다.',
                '로그인 하시겠습니까?'
            )
        }
    }

    const fetchData = async () => {
        const temp = search
        temp.page = page
        temp.rowSize = rowSize

        const result = isAdmin ?await getAdminBoardListApi(temp) : await getBoardListApi(temp)
        if (page != 1 && !result?.content) {
            setPage(page - 1)
        }
        setBoardList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
        setUserInfo(await getUserInfo())
    }

    useEffect(() => {
        fetchData()
    }, [productId, page, userId])
    return (
        <>
            {isAdmin && !noAnswer && (
                <SearchField
                    searchFieldList={boardSearch()}
                    actionFieldList={actionFieldList}
                    data={search}
                    setData={setSearch}
                />
            )}
            <ListTemplate
                header={<Header options={headerOptions} />}
                row={rowOptions}
                footer={<Footer options={footerOptions} />}
            />
            <PageBar
                rowSize={rowSize}
                totalCount={totalCount}
                page={page}
                setPage={setPage}
            />
        </>
    )
}

export default InquiryList
