import {useNavigate, useSearchParams} from 'react-router-dom'
import './index.module.scss'
import React, {useEffect, useState} from 'react'

import {getOrderApi} from '@Api/user/order'
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {OrderDto} from "@Types/order/orderDto";
import MainFrame from "@Component/layout/MainFrame";
import OrderInfo from "@Component/domains/order/OrderInfo";
import {transFormItem} from "@Service/order/OrderService";
import formStyle from '@Css/form.module.scss'
import styles from  './index.module.scss'
import Button from "@Component/common/Input/Button";

const OrderComplete = () => {
    const modal = getModalInstance()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const orderCode = searchParams.get('orderCode')

    const [order, setOrder] = useState<OrderDto.Response>()
    const [orderItemList, setOrderItemList] = useState<OrderDto.TransformedOrder[]>()
    const fetchData = async () => {
        if (!orderCode) {
            return
        }
        const response = await getOrderApi(orderCode);
        setOrder(response);

        // orderItemList 데이터 변환 후 설정
        if (response?.orderItemList) {
            setOrderItemList(transFormItem(response.orderItemList));
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        const status = ['입금대기', '결제완료']
        if (order && !status.includes(order?.orderState || '')) {
            modal?.alert(
                () => {
                    navigate('/')
                },
                '경고',
                '잘못된 접근입니다.'
            )
        }
    }, [order])

    return (
        <MainFrame className={styles.orderComplete} title={'감사합니다 주문이 완료되었습니다.'} >
            <OrderInfo orderDto={order} orderItemList={orderItemList}/>
            <div className={formStyle.flex}>
                <Button extraClass={formStyle.halfButton} name={"상세보기"} onClick={() => {
                    navigate('/mypage/orders')
                }}/>
                <Button extraClass={formStyle.halfButton} name={"쇼핑하기"} onClick={() => {
                    navigate('/product/list')
                }}/>
            </div>
        </MainFrame>
    )
}

export default OrderComplete