import React, {useEffect, useState} from 'react'
import {setWishicon} from "@Service/wish/WishService";
import {ProductDto} from "@Types/product";
import {Link} from "react-router-dom";
import ImageTag from "@Component/common/Image/ImageTag";
import styles from '@Css/product/productItem.module.scss'
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {ProductIcon} from "@Component/domains/product/ProductList/ProductIcon";
import ReviewRating from "@Component/domains/board/ReviewRating";

export const ProductList = (props: {
    productList: ProductDto.Response[] | null
    isAdmin?: boolean
}): JSX.Element => {
    const productList = props.productList
    const [adminUrl, setAdminUrl] = useState<string>('')

    useEffect(() => {
        if (props.isAdmin) {
            setAdminUrl('/admin')
        }
    }, [props.isAdmin])
    useEffect(() => {
        if (productList) {
            setWishicon()
        }
    }, [productList])

    if (!productList) {
        return <></>
    }

    return (
        <div className={styles.productContain}>
            {productList.map((product, index) => (
                <ProductGridItem
                    product={product}
                    adminUrl={adminUrl}
                    key={index}
                />
            ))}
        </div>
    )
}

export const ProductGridItem = ({
                                    product,
                                    adminUrl,
                                }: {
    product: ProductDto.Response
    adminUrl: string
}) => {
    return (
        <div className={styles.productItem} key={product.productId}>
            <Link
                className={styles.productImage}
                to={adminUrl + '/product/detail/' + product.productId}
            >
                <ImageTag
                    src={`${process.env.PRODUCT_IMAGE_BASE_URL}${product.productId}/THUMBNAIL/0.png`}
                    alt={`${product.name} thumbnail`}
                />
            </Link>
            <div className={styles.productInfo}>
                <p id={'product-title-' + product.productId}>
                    <Link
                        to={adminUrl + '/product/detail/' + product.productId}
                    >
                        {product.name}
                    </Link>
                </p>
                <p id={'product-price-' + product.productId}>
                    {(product.price ?? 0).toLocaleString('ko-KR')} 원
                </p>
                <ProductIcon product={product} rating={true}/>
            </div>
        </div>
    )
}

export const ProductListItem: React.FC<{
    product?: ProductDto.Response,
    mode?: string
}> = ({
          product,
          mode
      }) => {
    const modal = getModalInstance()
    if (!product) {
        return null
    }
    return (
        <div className={styles.productListItem + ' '
            + (mode == 'cart' ? styles.cartListItem : '') + ' '
            + (mode == 'order' ? styles.orderListItem : '')}>
            {mode != 'order' && (
                <Link
                    className={styles.productImage}
                    to={'/product/detail/' + product.productId}
                >
                    <ImageTag
                        src={`${process.env.PRODUCT_IMAGE_BASE_URL}${product.productId}/THUMBNAIL/0.png`}
                        alt={`${product.name} thumbnail`}
                    />
                </Link>)}
            {mode == 'order' ? (<div className={styles.productInfo}>
                <p className={styles.title}>{product.name}</p>
                <div className={styles.price}>
                    <p>가격 : {(product.price ?? 0).toLocaleString('ko-KR')} 원</p>
                    <p>배송비 : {(product.deliveryFee ?? 0).toLocaleString('ko-KR')} 원</p>
                </div>
            </div>) : (
                <div className={styles.productInfo}>
                    < Link to={'/product/detail/' + product.productId}
                           onClick={modal?.closeModal}>
                        <p className={styles.category}>{product.category?.primaryName + ' > ' + product.category?.secondaryName}</p>
                        <p className={styles.title}>{product.name}</p>
                        <p>
                            {(product.price ?? 0).toLocaleString('ko-KR')} 원
                        </p>
                        <div>
                            <ReviewRating data={product} name={'reviewRating'}/>({product.reviewCount})
                        </div>
                    </Link>
                    {mode !='cart' && <ProductIcon product={product} />}
                </div>)}
        </div>
    )
}