import ReactDOM from 'react-dom/client'
import '@Css/cmm.css'
import Router from './Router'
import '@Css/fontello.css'
import ReactModal from 'react-modal'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
ReactModal.setAppElement('#root')

root.render(
    <Router />
)
