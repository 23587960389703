import {useEffect, useState} from "react";
import {getViewedProduct} from "@Service/product/ProductService";
// import {ProductService} from "@Api/user/product/ProductService";
import {setWishicon} from "@Service/wish/WishService";
import {ProductListItem} from "@Component/domains/product/ProductList";
import MainFrame from "@Component/layout/MainFrame";
import {ProductDto} from "@Types/product";


export const ViewProduct = () => {
    const [productList, setProductList] = useState<ProductDto.Response[]>([])
    const fetchData = async () => {
        const tempList: ProductDto.Response[] = []
        const productIdList = await getViewedProduct()
        setProductList(productIdList)
        await setWishicon()
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainFrame title={'최근 본 상품'} maxWidth={1000} marginZero={true}>
        {productList?.map((product,index) => (
                <ProductListItem key={index} product={product} />
            ))}
            {productList.length == 0 && (
                <div className="empty-view">
                    최근 본 상품이 없습니다.
                </div>
            )}
        </MainFrame>
    )
}
