import React, {useEffect, useRef, useState} from 'react';
import html2canvas from 'html2canvas';
import './3dImageMaker.css'
import hoodie from '@Image/3d-base/Hoodie.png';
import tShirt from '@Image/3d-base/T-Shirt.png';
import htShirt from '@Image/3d-base/H-T-Shirt.png';
import basketball from '@Image/3d-base/BasketBall.png';


import CropperJS from 'cropperjs';
import Cropper, {ReactCropperElement} from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import CanvasView from "@Component/common/3D/3dCnvas";

const images = [hoodie, htShirt, tShirt, basketball, '']
const models = ['HoodT', 'T-Shirt', 'Shirt', 'BasketBall', '']

const DelHandler = (index: string | number) => {
    const consoleEl = document.getElementById(`append-image-console-${index}`);
    const consoleImage = document.getElementById(`append-image-${index}`);

    if (consoleEl) {
        consoleEl.remove()
    }
    if (consoleImage) {
        consoleImage.remove()
    }

  
}
const SHandler = (index: string | number) => {
    const el = document.getElementById(`slider-form-${index}`);

    if (el) {
        if (el.style.display === 'none' || el.style.display === '') {
            el.style.display = 'block';
        } else {
            el.style.display = 'none';
        }
    }
}
const XYHandler = (index: string | number) => {
    const elX = document.getElementById(`slider-form-x-${index}`);

    if (elX) {
        if (elX.style.display === 'none' || elX.style.display === '') {
            elX.style.display = 'block';
        } else {
            elX.style.display = 'none';
        }
    }

    const elY = document.getElementById(`slider-form-y-${index}`);

    if (elY) {
        if (elY.style.display === 'none' || elY.style.display === '') {
            elY.style.display = 'block';
        } else {
            elY.style.display = 'none';
        }
    }

}

const transformImage = (index: string | number, x: string | number, y?: string | number) => {
    const el = document.getElementById(`append-image-${index}`);
    if (!el) {
        return
    }
    // 정규 표현식을 사용하여 숫자와 단위 추출
    const regex = /translate\((-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/;
    const match = el.style.transform.match(regex);

    // 기존 값을 추출하거나 기본값 설정
    const existingX = match ? parseFloat(match[1]) : 0;
    const existingY = match ? parseFloat(match[2]) : 0;

    // x와 y 값이 제공되지 않은 경우 기존 값 사용
    const newX = x ? Number(x) : existingX ;
    const newY = y ? Number(y) : existingY;

    // 새로운 transform 속성 적용
    el.style.transform = `translate(${newX}px, ${newY}px)`;


}


export const Make3DCanvas = () => {
    const modal = getModalInstance();
    const divRef = useRef<HTMLDivElement>(null);
    const [dataURL, setDataURL] = useState<string>('')
    const [baseImage, setBaseImage] = useState<string>('')
    const [model, setModel] = useState<string>('')
    const [color, setColor] = useState<string>('#ffffff');
    const [appendImage, setAppendImage] = useState<string[]>([])
    const [cropper, setCropper] = useState<boolean>(false)

    const transformScaleImage = (index: string | number, val: string | number) => {
        const el = document.getElementById(`append-image-${index}`);
        if (!el) {
            return
        }
        if (divRef.current) {
            el.style.width = divRef.current.clientWidth / 2 * Number(val) + 'px';
        }
    }
    const setData = async () => {
        if (model === '') {
            modal?.alert(() => {}, '알림', '3D 모델을 선택해주세요.');
            return; // 모델이 선택되지 않았을 경우, 나머지 코드 실행을 중지
        }

        if (divRef.current) {
            const captureElement = divRef.current.cloneNode(true) as HTMLElement;
            captureElement.style.position = 'fixed';
            captureElement.style.left = '-9999px'; // 화면 밖으로 이동
            captureElement.style.overflow = 'visible';
            captureElement.style.width = divRef.current.clientWidth + 'px';

            // 캡처할 요소의 크기가 최대 크기를 넘지 않도록 조정
            const originalWidth = divRef.current.clientWidth;
            const originalHeight = divRef.current.clientHeight;

            // 캔버스 크기의 제한값
            const maxCanvasSize = 16000000; // 최대 픽셀 수

             const   scaleValue = Math.sqrt(maxCanvasSize / (originalWidth * originalHeight));


            const rotateValue = model === '1' ? '180deg' : '0deg';
            captureElement.style.transform = `rotate(${rotateValue})`;

            // 캡처용 요소를 body에 추가
            document.body.appendChild(captureElement);

            try {
                // html2canvas 호출을 async/await로 처리
                const canvas = await html2canvas(captureElement, {
                    useCORS: true, // CORS 정책을 적용하여 외부 리소스를 허용
                    scale: scaleValue, // 조정된 스케일 값 사용
                });

                // 데이터 URL을 생성
                setDataURL(canvas.toDataURL('image/png'));
            } catch (error) {
                console.error('Error capturing the element', error);
            } finally {
                // 캡처용 요소를 삭제하고 원본 스타일 복원
                document.body.removeChild(captureElement);
            }
        }
    };
    const handleCapture = async () => {
        // 모델이 선택되지 않은 경우, 알림을 띄우고 함수 종료
        if (model === '') {
            modal?.alert(() => {}, '알림', '3D 모델을 선택해주세요.');
            return;
        }

        if (divRef.current) {
            // 새로운 캡처용 요소 생성 및 스타일 설정
            const captureElement = divRef.current.cloneNode(true) as HTMLElement;
            captureElement.style.position = 'fixed'; // 화면에서 고정
            captureElement.style.left = '-9999px'; // 보이지 않도록 화면 밖으로 이동
            captureElement.style.overflow = 'visible'; // 요소의 넘치는 콘텐츠가 보이도록 설정
            captureElement.style.width = divRef.current.clientWidth + 'px'; // 원본 요소의 너비 설정

            const originalWidth = divRef.current.clientWidth;
            const originalHeight = divRef.current.clientHeight;

            // 최대 캔버스 크기 (브라우저 최대 픽셀 제한: 16777216)
            const maxCanvasSize = 16000000; // 최대 픽셀 수
            const   scaleValue = Math.sqrt(maxCanvasSize / (originalWidth * originalHeight));
            // 회전 값 설정
            const rotateValue = model === '1' ? '180deg' : '0deg';
            captureElement.style.transform = `rotate(${rotateValue})`;

            // 캡처용 요소를 body에 추가 (캡처하기 위해 DOM에 추가)
            document.body.appendChild(captureElement);

            try {
                // html2canvas를 사용해 캡처를 비동기로 처리
                const canvas = await html2canvas(captureElement, {
                    useCORS: true, // 외부 리소스(CORS)를 허용
                    scale: scaleValue, // 계산된 스케일 값 사용
                });

                // 캡처된 캔버스를 데이터 URL로 변환 (PNG 형식)
                const dataURL = canvas.toDataURL('image/png');

                // 다운로드 링크 생성 및 클릭을 통한 이미지 다운로드
                const link = document.createElement('a');
                link.href = dataURL;
                link.download = model + '.png'; // 모델명을 파일명으로 사용
                link.click(); // 다운로드 링크 자동 클릭
            } catch (error) {
                // 캡처 중 에러 발생 시 로그 출력
                console.error('Error capturing the element', error);
            } finally {
                // 캡처용으로 추가한 요소를 DOM에서 제거
                document.body.removeChild(captureElement);
            }
        }
    };



    return (
        <div className='make-form'>
            <div className='make-canvas'>


                <div className='make-console'>
                    <label>모델 선택</label>
                    <select
                        onChange={async (event: React.ChangeEvent<HTMLSelectElement>) => {
                            setBaseImage(images[parseInt(event.target.value)])
                            setModel(models[parseInt(event.target.value)])
                            setDataURL('')
                            if (divRef.current) {
                                if (event.target.value === '2') {
                                    divRef.current.style.transform = 'rotate(180deg)';
                                } else {
                                    divRef.current.style.transform = 'rotate(0deg)';
                                }
                            }


                        }}>
                        <option value={4}>선택</option>
                        <option value={0}>후드티</option>
                        <option value={1}>반팔</option>
                        <option value={2}>긴팔</option>
                        <option value={3}>농구복</option>
                    </select>
                    <label>색상 선택</label>
                    <input className='bg-white-button' type="color" id="colorPicker" defaultValue={'#ffffff'}
                           onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {

                               setColor(event.target.value)
                           }}/>

                    <label>추가 이미지</label>
                    {appendImage.map((image, index) => {
                        if (image != '') {
                            return (<div id ={'append-image-console-' + index}>
                                    <div className = {'append-image'}>
                                        <p>이미지 {index}</p>
                                        <div>
                                            <i className={'icon-move'} onClick={() => {
                                                XYHandler(index)

                                            }}></i>
                                            <i className={'icon-zoom-in'} onClick={() => {

                                                SHandler(index)
                                            }}></i>
                                            <i className={'icon-trash'}  onClick={() => {
                                                modal?.confirm(()=>{ DelHandler(index)},'확인','추가 이미지를 삭제하시겠습니까?')
                                                
                                            }}
                                            ></i>
                                        </div>
                                    </div>
                                    <div id={"slider-form-x-" + index}>
                                        <label id={"slider-label-x-" + index}>좌우조절 <input type={"button"} value={"확인"}
                                                                                          className={'bg-white-button'}
                                                                                          onClick={() => {
                                                                                              XYHandler(index)
                                                                                              console.log(appendImage)
                                                                                          }}/></label>
                                        <input
                                            type="range"
                                            id={"slider-x-" + index}
                                            min="-500"
                                            max="500"
                                            step="0.01"
                                            defaultValue="1"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                transformImage(index, e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div id={"slider-form-y-" + index}>
                                        <label id={"slider-label-y-" + index}>상하조절</label>
                                        <input
                                            type="range"
                                            id={"slider-y-" + index}
                                              min="-500"
                                            max="500"
                                            step="0.01"
                                            defaultValue="1"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                transformImage(index, '', e.target.value)
                                            }}
                                        />
                                    </div>
                                    <div id={"slider-form-" + index}>
                                        <label id={"slider-label-" + index}>크기조절<input type={"button"} value={"확인"}
                                                                                       className={'bg-white-button'}
                                                                                       onClick={() => {
                                                                                           SHandler(index)
                                                                                       }}/></label>
                                        <input
                                            type="range"
                                            id={"slider-" + index}
                                            min="0"
                                            max="5"
                                            step="0.01"
                                            defaultValue="1"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                transformScaleImage(index, e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>


                            )
                        }
                    })}
                    <input className='bg-white-button' type='button' onClick={() => {
                        if (model === '') {
                            modal?.alert(() => {
                            }, '알림', '3D 모델을 선택해주세요.');
                            return; // 모델이 선택되지 않았을 경우, 나머지 코드 실행을 중지
                        }
                        setCropper(true)
                    }} value={'사진추가'}/>

                    {cropper &&
                        <ImageCropper appendImage={appendImage} setAppendImage={setAppendImage}
                                      setCropper={setCropper}/>}
                    <input className='bg-white-button' type='button' value={'3D 생성'} onClick={setData}/>
                    <input className='bg-white-button' type='button' onClick={handleCapture} value={'다운로드'}/>
                </div>
                <div ref={divRef} className='image-frame' style={{backgroundColor: color}}>
                    {appendImage.map((image, index) => (
                        <img style={{transform: 'translate( -50% ,-50%)'}} className={'append-image-item'}
                             id={'append-image-' + index} key={index} src={image}/>
                    ))}
                    <img src={baseImage}/>
                </div>
                <div className='image-viewer'></div>
            </div>
            {dataURL && (<CanvasView imageUrl={dataURL} model={model}/>)}
        </div>
    );
};


const ImageCropper = (props: {
    appendImage: string[],
    setAppendImage: React.Dispatch<React.SetStateAction<string[]>>
    setCropper: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const modal = getModalInstance();
    const [image, setImage] = useState<File | null>(null);
    const [cropData, setCropData] = useState<string>('');
    const cropperRef = useRef<CropperJS | null>(null); // CropperJS 인스턴스 타입으로 설정

    useEffect(() => {
        // Clean up the image URL when component unmounts
        return () => {
            if (image) {
                URL.revokeObjectURL(URL.createObjectURL(image));
            }
        };
    }, [image]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleCrop = () => {
        if (cropperRef.current) {
            let temp = props.appendImage
            temp.push(cropperRef.current.getCroppedCanvas().toDataURL() || '')
            props.setAppendImage(temp)
            props.setCropper(false)
        } else {
            modal?.alert(() => {
            }, '알림', '이미지가 등록되지 않았습니다.')
        }
    };

    return (
        <div>

            <div className={'cropper-input'}><input type="file" accept="image/*" onChange={handleImageChange}/>
                < input type={"button"} className={'bg-white-button'} onClick={handleCrop} value={'적용'}/> < input
                    type={"button"} className={'bg-white-button'} onClick={()=>{props.setCropper(false)}} value={'취소'}/></div>

            {image && (
                <Cropper
                    ref={(cropper: ReactCropperElement) => {
                        setTimeout(() => {
                            cropperRef.current = cropper.cropper; // Cropper 인스턴스에 접근
                        }, 100)
                    }}
                    src={URL.createObjectURL(image)}
                    style={{height: 400, width: '100%'}}
                    guides={false}
                />
            )}
            {cropData && (
                <div>
                    <img src={cropData} alt="Cropped" style={{width: '100px'}}/>
                </div>
            )}
        </div>
    );
};
