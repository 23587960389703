import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {Address} from "@portone/browser-sdk/dist/v2/entity";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {deleteRequest, getRequest, postRequest} from "@Api/Api";
import {AddressDto} from "@Types/address";


export const saveAddressApi= async (address: AddressDto.Save) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(() => {}, '알림', '저장되었습니다.')
            },
        }
        const httpAlert: HttpAlert = {}

        return await postRequest(
            '/address',
            address,
            httpSuccess
        )
    } catch (e) {
        console.log(e)
    }
}

export const getAddressListApi = async () => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {

        }
        const httpAlert: HttpAlert = {
            401: ()=>{
                modal?.alert(()=>{
                    window.location.href='/user/login'
                },'알림','이용을위해 로그인이 필요합니다.')
            }
        }

        return await getRequest<AddressDto.Response[]>(
            '/address/list',
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const deleteAddressApi = async (
    addressId: string,
) => {
    const modal = getModalInstance()

    try {
        const httpSuccess: HttpSuccess = {
            200: () => {
                modal?.alert(
                    async () => {},
                    '알림',
                    '삭제되었습니다.'
                )
            },
        }
        const httpAlert: HttpAlert = {}

        return await deleteRequest<Address[]>(
            '/address/' + addressId,
            httpSuccess,
        )
    } catch (e) {
        console.log(e)
    }
}
