import { FormFieldProps } from 'components/layout/FormField'
import { bankOptions } from '@Types/order'

export const paymentType = [
    ...(process.env.PAYMENT_READY == 'true'
        ? [
              {
                  label: '신용카드/간편결제',
                  value: 'PAYMENT',
              },
          ]
        : []),
    {
        label: '계좌이체',
        value: 'ACCOUNT',
    },
]

export const formList: FormFieldProps[] = [
    {
        placeholder: '결제수단을 선택해주세요',
        inputType: 'select',
        name: 'transactionType',
        options: paymentType,
    },
]

export const formList2: FormFieldProps[] = [
    {
        placeholder: '결제수단을 선택해주세요',
        inputType: 'select',
        name: 'transactionType',
        options: paymentType,
    },
    {
        placeholder: '결제 은행을 선택해주세요',
        inputType: 'select',
        name: 'accountCode',
        options: bankOptions,
    },
    {
        placeholder: '예금주',
        inputType: 'text',
        name: 'accountHolder',
    },
    {
        placeholder: '계좌번호',
        inputType: 'text',
        name: 'accountNumber',
    },
]
