import {ValidationOption} from "@Util/formUtils";
import {ProductDto} from "@Types/product";
import {CheckDuplicate} from "@Api/admin/product";

export const saveValid: ValidationOption[] = [
    {
        name: 'productCode',
        baseMessage: '상품 코드를',
        duplication:  (value) => {
            const duplicationDto: ProductDto.Duplication = {
                productId: value.productId,
                productCode: value.productCode
            }

            return  CheckDuplicate('CODE', duplicationDto)
        }
    },
    {
        name: 'name',
        baseMessage: '상품 이름을',
    },
    {
        name: 'price',
        baseMessage: '상품 가격을',
    },
    {
        name: 'deliveryFee',
        baseMessage: '배송비를',
    },
    {
        name: 'deliveryFeeThreshold',
        baseMessage: '배송비기준',
    },
    {
        name: 'size',
        baseMessage: '사이즈를',
    },
    {
        name: 'productDescription',
        baseMessage: '상품 설명을',
    },
];
