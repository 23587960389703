import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {getRequest, postRequest} from "@Api/Api";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {CategoryDto, CategoryList} from "@Types/category";
import {UserDto} from "@Types/user";
import {Address} from "@portone/browser-sdk/dist/v2/entity";
import {AddressDto} from "@Types/address";

export const MainImageService = async () => {
    try {
        const httpAlert: HttpAlert = {}
        const httpSuccess: HttpSuccess = {}

        return await getRequest<string[]>(
            '/main-image',
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
    }
}

export const updateMainImage = async (formData: FormData) => {
    const modal = getModalInstance()
    const httpSuccess: HttpSuccess = {
        200: () => {
            modal!.alert(
                () => {
                    const referrer = localStorage.getItem('referrer')
                    window.location.href = referrer != null ? referrer : '/'
                },
                '알림',
                '메인 이미지가 성공적으로 수정되었습니다.'
            )
        },
    }
    const httpAlert: HttpAlert = {}

    try {
        await postRequest(
            '/admin/main-image',
            formData,
            httpSuccess,
            httpAlert,
            {
                headers: {
                    'Content-Type': 'multipart/form-data', // 멀티파트 요청의 Content-Type 지정
                },
            }
        )
    } catch (e) {
        console.log(e)
    }
}

let categoryList :CategoryList = {}
export const getCategoryList = async (refresh?:boolean)  => {

    try {
        if(Object.getOwnPropertyNames(categoryList).length != 0 && !refresh){
            return categoryList
        }
        const data = await getRequest<CategoryDto.Response[]>(
            '/product/category'
        )

        if (data) {
            categoryList = {}

            const sortData = data.sort((a, b) =>
                a.primaryName.localeCompare(b.primaryName)
            )


            sortData.forEach((category) => {
                if (!categoryList[category.primaryName]) {
                    categoryList[category.primaryName] = []
                }
                categoryList[category.primaryName].push(category)
            })

            // 카테고리별로 요소를 정렬합니다
            Object.keys(categoryList).forEach(key => {
                categoryList[key].sort((a, b) => a.menuOrderSd! - b.menuOrderSd!);
            });

            // JSX를 사용하지 않고 단순한 정보를 반환
            return categoryList
        }
    } catch (error) {
        console.error('에러 발생:', error)

    }

    return {};
}

export const createAuthTokenApi = async (cert: UserDto.Cert) => {
    try {
        const modal = getModalInstance()

        const httpSuccess: HttpSuccess = {
            200: () => {
                modal!.alert(
                    () => {},
                    '알림',
                    '인증번호가 발급되었습니다.',
                    '3분내에 입력해주세요.'
                )
            },
        }

        await postRequest(
            '/auth-token',
            cert,
            httpSuccess,
        )
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export const searchAddressService = async (search: AddressDto.Search) => {
    try {
        const modal = getModalInstance()
        const httpAlert: HttpAlert = {
        }
        const httpSuccess: HttpSuccess = {}

        return await getRequest<any>(
            '/address',
            httpSuccess,
            httpAlert,
            {
                params: search
            }
        )
    } catch (e) {
        console.log(e)
    }
}
