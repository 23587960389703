import React, {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
// import {OrderPageBar} from "@Component/common/Index/Index";
import {createSearchDto, OrderDto} from '@Types/order'
import {PageBar} from '@Component/common/PageBar'

import MainFrame from '@Component/layout/MainFrame'
import SearchField, {ActionField} from '@Component/layout/SearchField'
import getAdminOrderSearch, {OrderType, userSearch} from './searchFeild'
import {OrderFlexItem, OrderTableItem} from './OrderItem'
import {getOrderListApi} from "@Api/user/order";
import styles from './index.module.scss'
import {getAdminOrderListApi} from "@Api/admin/order";

export const OrderList = () => {
    const [page, setPage] = useState<number>(1)
    let search: OrderDto.Search,
        setSearch: (value: (((prevState: OrderDto.Search) => OrderDto.Search) | OrderDto.Search)) => void;
    [search, setSearch] = useState<OrderDto.Search>(createSearchDto);
    const [orderList, setOrderList] = useState<OrderDto.Response[]>([])
    const [totalCount, setTotalCount] = useState<number>(1)
    const rowSize = 6
    const fetchData = async () => {
        const tempSearch = search
        tempSearch.page = page
        tempSearch.rowSize = rowSize
        const result = await getOrderListApi(tempSearch)
        console.log(result)
        setOrderList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }

    useEffect(() => {
        fetchData()
    }, [page])

    const actionFieldList: ActionField[] = [{
        label: '검색',
        onClick: () => {
            fetchData()
        },
    }]

    return (
        <MainFrame>
            <SearchField
                searchFieldList={userSearch}
                data={search}
                setData={setSearch}
                actionFieldList={actionFieldList}
            />
            <MainFrame>
                {orderList?.map((order, index) => (
                    <OrderFlexItem order={order}/>
                ))}
                {orderList?.length === 0 && (
                    <div className="empty-view">해당하는 주문이 없습니다.</div>
                )}
            </MainFrame>
            <PageBar
                page={page}
                totalCount={totalCount}
                setPage={setPage}
                rowSize={rowSize}
            />
        </MainFrame>
    )
}

export const AdminOrderList = () => {
    const [searchParams] = useSearchParams()
    const orderType = searchParams.get('orderType')
    const isValidOrderType = (value: any): value is OrderType => {
        return ['주문', '취소', '교환', '반품'].includes(value);
    };

    const validOrderType: OrderType = isValidOrderType(orderType) ? orderType : '주문';


    const [page, setPage] = useState<number>(1)
    const [search, setSearch] = useState<OrderDto.Search>(createSearchDto);
    const [orderList, setOrderList] = useState<OrderDto.Response[]>([])
    const [totalCount, setTotalCount] = useState<number>(1)
    const rowSize = 6

    const fetchData = async (pageReset?: boolean, orderType?: string) => {
        const tempSearch = search
        tempSearch.page = pageReset ? 1 : page
        tempSearch.rowSize = rowSize
        tempSearch.orderState = orderType !== undefined ? orderType : tempSearch.orderState;
        const result = await getAdminOrderListApi(tempSearch)
        setOrderList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }


    const actionFieldList: ActionField[] = [{
        label: '검색',
        onClick: () => {
            if (page == 1) {
                fetchData(true)
            } else {
                setPage(1)
            }
        },
    }]


    useEffect(() => {
        fetchData()
    }, [page])


    useEffect(() => {
        const orderState = validOrderType != '주문' ? validOrderType : ''
        setSearch({
            ...search,
            orderState: orderState
        });
        fetchData(true, orderState)
    }, [validOrderType])
    return (
        <MainFrame>
            <SearchField
                searchFieldList={getAdminOrderSearch(validOrderType)}
                data={search}
                setData={setSearch}
                actionFieldList={actionFieldList}
            />
            <MainFrame>
                <table className={styles.orderTable}>
                    <thead>
                    <tr>
                        <th colSpan={8}>주문명</th>
                    </tr>

                    <tr>
                        <th colSpan={2}>주문상태</th>
                        <th colSpan={2}>결제수단</th>
                        <th colSpan={2}>주문자명</th>
                        <th colSpan={2}>주문일자</th>
                    </tr>

                    <tr>
                        <th colSpan={3}>주문번호</th>
                        <th colSpan={3}>배송번호</th>
                        <th colSpan={2}>주문금액</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderList?.map((order, index) => (
                        <OrderTableItem order={order}/>
                    ))}
                    {orderList?.length === 0 && (
                        <tr>
                            <td colSpan={8}>
                                <div className={'empty-view'}>
                                    해당하는 주문이 없습니다.
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </MainFrame>
            <PageBar
                page={page}
                totalCount={totalCount}
                setPage={setPage}
                rowSize={rowSize}
            />
        </MainFrame>
    )
}
