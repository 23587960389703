// src/components/SwaggerViewer.tsx
import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import * as process from "process";
import MainFrame from "@Component/layout/MainFrame"; // Swagger UI의 기본 스타일을 가져옵니다.


const SwaggerViewer = () => {

    return <MainFrame title={'API 명세서'} documentTitle={'API'} maxWidth={1400}>
        <SwaggerUI url={process.env.BASE_URL! + '/admin/swagger'} docExpansion="none"/>
    </MainFrame>
};

export default SwaggerViewer;
