import {getModalInstance} from "@Component/common/Modal/ModalProvider";

import {orderWebHookService} from "@Api/user/order";
import * as PortOne from "@portone/browser-sdk/v2";
import {UserDto} from "@Types/user"
import {OrderDto} from "@Types/order";
import {CartDto} from "@Types/cart";
import React, {SetStateAction} from "react";


export const transFormItem = (itemList: OrderDto.Item[]) => {
    const transformedList: OrderDto.TransformedOrder[] | CartDto.TransformedCart[] = []

    itemList.forEach((item: OrderDto.Item) => {
        if (
            !transformedList.some(
                (trans) =>
                    trans?.product?.productId === item?.product?.productId
            )
        ) {
            transformedList.push({
                product: item?.product!,
                sizes: [],
            })
        }

        transformedList
            .find(
                (trans) =>
                    item?.product?.productId === trans?.product?.productId
            )
            ?.sizes.push({
            size: item.size,
            count: item.count,
        })
    })

    return transformedList
}
export const calculatePrice = (
    orderList?: OrderDto.TransformedOrder[],
    setTotalPrice?: React.Dispatch<SetStateAction<number>>,
    setTotalDeliveryFee?: React.Dispatch<SetStateAction<number>>,
    selectDto?: Record<string, boolean> // selectDto는 선택사항
) => {
    if (!setTotalPrice || !setTotalDeliveryFee || !orderList) {
        return;
    }

    let calculatedTotalPrice = 0;
    let minDeliveryFee = Number.MAX_VALUE;
    let minDeliveryFeeThreshold = Number.MAX_VALUE;

    const hasSelectedProducts = selectDto
        ? orderList.some(order => selectDto[order.product.productId!]) // selectDto가 있을 때
        : orderList.length > 0; // selectDto가 없으면 전체 상품을 계산

    if (hasSelectedProducts) {
        orderList.forEach((order) => {
            // selectDto가 없으면 전체 상품 계산, 있으면 선택된 상품만 계산
            if (!selectDto || selectDto[order.product.productId!]) {
                // 각 아이템의 개수만큼 가격을 곱함
                order.sizes.forEach((item) => {
                    const productPrice = parseInt(order.product.price || "0", 10);
                    const itemCount = item.count || 0; // 개수 (count)
                    calculatedTotalPrice += productPrice * itemCount; // 가격 * 개수

                    const deliveryFee = parseInt(order.product.deliveryFee || "0", 10);
                    const deliveryFeeThreshold = parseInt(order.product.deliveryFeeThreshold || "0", 10);

                    if (deliveryFeeThreshold < minDeliveryFeeThreshold) {
                        minDeliveryFeeThreshold = deliveryFeeThreshold;
                    }

                    if (deliveryFee < minDeliveryFee) {
                        minDeliveryFee = deliveryFee;
                    }
                });
            }
        });

        // 총 가격이 최소 배송비 조건을 넘으면 무료 배송
        if (calculatedTotalPrice >= minDeliveryFeeThreshold) {
            minDeliveryFee = 0;
        }

        setTotalPrice(calculatedTotalPrice);
        setTotalDeliveryFee(minDeliveryFee);
    } else {
        // 선택된 상품이 없을 경우 초기화
        setTotalPrice(0);
        setTotalDeliveryFee(0);
    }
};


export const onClickPayment = async (

    user: UserDto.Info,
    orderDto: OrderDto.Response
) => {
    const modal = getModalInstance()
    try {
        const response = await PortOne.requestPayment({
            // Store ID 설정
            storeId: process.env.PORTONE_STORE_ID!,
            // 채널 키 설정
            channelKey: process.env.KG_INICIS_KEY!,
            paymentId: orderDto?.orderCode!,
            // 주문목록
            orderName: orderDto?.orderName!,
            // 주문가격
            totalAmount: orderDto?.paymentAmount!,

            currency: 'CURRENCY_KRW',
            payMethod: 'CARD',

            // 유저정보
            customer: {
                email: user.email!,
                phoneNumber: user.phoneNumber!,
                fullName: user.userName!,
            },

            redirectUrl: `${process.env.REDIRECT_URL}`,
        })

        if (response && response.code != null) {
            modal?.alert(() => {
            }, '알림', '결제가 취소되었습니다.')
            return;
        }

        await orderWebHookService(response!)
    } catch (e) {
        if (e instanceof Error && e.message.includes('이미 결제된 id입니다.')) {
            modal?.alert(
                () => {
                    window.location.href = '/user/mypage/orders'
                },
                '알림',
                '이미 결제된 주문입니다.'
            )
        }
    }
}
