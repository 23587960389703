import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';

export const defaultSizeList = [
    '5xs', '4xs', '3xs', '2xs', 'xs', 's', 'm', 'l', 'xl', '2xl',
    '3xl', '4xl', '5xl', '6xl', '7xl', 'free', 'custom',
];

interface SizeSelectorProps {
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    productSize?: string;
}

const SizeSelector: React.FC<SizeSelectorProps> = ({ data, setData, productSize }) => {
    const [sizeList, setSizeList] = useState<string[]>(defaultSizeList);

    useEffect(() => {
        if (productSize) {
            setSizeList(productSize.split(','));
        } else {
            setSizeList(defaultSizeList);
        }
    }, [productSize]);

    const clickHandler = (size: string) => {
        // Create a copy of the data object
        const temp = { ...data };

        // Initialize tempList as an empty array
        let tempList: string[] = [];

        // Ensure temp.size is a string before attempting to split
        if (temp.size) {
            tempList = temp.size.split(',');
        }

        // Add or remove the size from the list
        if (tempList.includes(size)) {
            tempList = tempList.filter((item: string) => item !== size);
        } else {
            tempList.push(size);
        }

        // Update the size property of the temp object
        temp.size = tempList.join(',');

        // Set the updated data
        setData(temp);
    };

    return (
        <div className={styles.sizeSelector}>
            {sizeList.map((size, index) => (
                <div
                    key={index}
                    id={size}
                    className={data.size && data.size.split(',').includes(size) ? styles.active : ''}
                    onClick={() => clickHandler(size)}
                >
                    {size.toUpperCase()}
                </div>
            ))}
        </div>
    );
};

export default SizeSelector;
