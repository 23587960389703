import React from 'react';
import {Link} from 'react-router-dom';

export interface LinkItem {
    path: string;
    label: string;
    id?: string;
}

interface LinkBuilderProps {
    linkMap: LinkItem[];
    onClickEvent?: () => void;
}

const LinkBuilder: React.FC<LinkBuilderProps> = ({ linkMap, onClickEvent }) => {
    return (
        <>
            {linkMap.map(({ path, label,id }) => (
                <Link
                    key={path}
                    to={path}
                    onClick={onClickEvent}
                    id = {id}
                >
                    {label}
                </Link>
            ))}
        </>
    );
};

export default LinkBuilder;
