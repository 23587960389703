import {useEffect, useState} from "react";
import {MainImageService, updateMainImage} from "@Api/cmm/CmmService";
import {appendImageList, getFileMap} from "@Util/imageUtils";
import ImageDrop from "@Component/common/Image/ImageDrop/ImageDrop";
import MainImage from "@Component/domains/cmm/MainImage/MainImage";
import MainFrame from "@Component/layout/MainFrame";
import styles from '@Css/form.module.scss'

export const AdminMainImagePage = () => {
    const [fileMap, setFileMap] = useState<Map<number, File>>(new Map())

    const [logoFileMap, setLogoFileMap] = useState<Map<number, File>>(new Map())


    const fetchFileMap = async () => {
        const imageList = await MainImageService()
        const tempList = [ '/upload/logo/logo.png']
        if (imageList) {
            setFileMap(await getFileMap(imageList))
        }
        if(tempList){
            setLogoFileMap(await getFileMap(tempList))
        }
    }

    const saveMainImage = async () => {
        // 사용 예시
        const formData = new FormData();
        appendImageList(formData, fileMap); // 첫 번째 파일 리스트 추가
        appendImageList(formData, logoFileMap, 'logo'); // 두 번째 파일 리스트 추가
        await updateMainImage(formData)
    }

    useEffect(() => {
        fetchFileMap()
    }, [])

    return (
        <MainFrame className={styles.form} title={'메인 이미지 수정'} documentTitle={'메인 이미지 수정'} action={[{name:'저장',onClick:saveMainImage}]}>
            <h3 className={styles.flex}>
                로고 이미지 수정
            </h3>
            <ImageDrop
                fileMap={logoFileMap}
                setFileMap={setLogoFileMap}
                maxFiles={1}
            />

            <h3>
                메인 이미지 수정
            </h3>
            <ImageDrop
                fileMap={fileMap}
                setFileMap={setFileMap}
            />
            <MainImage fileMap={fileMap}/>
        </MainFrame>
    )
}
