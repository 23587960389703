import React, {useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './index.module.scss'

interface TabOption {
    label: string;
    link: string;
}

interface TabBarProps {
    options: TabOption[];
}

export const TabBar: React.FC<TabBarProps> = ({ options }) => {
    const location = useLocation();
    const path = decodeURIComponent(location.pathname + location.search); // 경로와 쿼리 문자열을 디코딩

    useEffect(() => {
        const activeOption = options.find(option => path.includes(option.link));
        const pageTitle = activeOption ? activeOption.label : 'Page';

        document.title = `${process.env.SITE_NAME} ${pageTitle}`;
    }, [options, path]);

    return (
        <div className={styles.tab}>
            {options.map((option, index) => (
                <Link
                    key={index}
                    className={path.includes(option.link) ? styles.active : ''}
                    to={option.link}
                >
                    {option.label}
                </Link>
            ))}
        </div>
    );
};
