

import { getUserInfo, userLogoutApi,} from '@Api/user/account'
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {UserDto} from "@Types/user";

export const kakaoLogin = (accountLink?: boolean) => {
    const redirectUri = new URL(process.env.KAKAO_REDIRECT_URL!); // URL 객체 생성
    redirectUri.searchParams.append('accountLink', accountLink ? 'true' : 'false'); // 파라미터 추가

    window.location.href =
        `https://kauth.kakao.com/oauth/authorize?response_type=code` +
        `&client_id=${process.env.KAKAO_CLIENT_ID}` +
        `&redirect_uri=${encodeURIComponent(redirectUri.toString())}`; // URL 인코딩하여 추가
}



export const checkUserInfo = async (
    setUserInfo?: React.Dispatch<React.SetStateAction<UserDto.Info | undefined>>,
    alert?: boolean
) => {
    const modal = getModalInstance()
    const userInfo = await getUserInfo()

    if (!userInfo) {
        if (alert) {
            modal?.alert(
                () => {
                    window.location.href = '/user/login'
                },
                '알림',
                '잘못된 접근입니다.',
                '이용을 위해 로그인이 필요합니다.'
            )
        }
        return false
    }

    if (setUserInfo) {
        setUserInfo(userInfo)
    } else {
        return true
    }
}


export const preventLoggedInAccess = async () => {
    const modal = getModalInstance();
    const userInfo = await getUserInfo();

    // 이미 로그인된 상태일 경우
    if (userInfo) {
            modal?.alert(
                () => {
                    window.location.href = '/';
                },
                '알림',
                '잘못된 접근입니다.',
                '로그아웃 후 이용해주세요.'
            );
        return false;
    }

    return true;
};
