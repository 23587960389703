import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import './MainImage.css'
import {onErrorImg} from '@Util/imageUtils'

function MainImage(props: { fileMap: Map<number, File> | undefined }) {
    const [fileMap, setFileMap] = useState<Map<number, File>>()
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    const goToPreviousImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? fileMap!.size - 1 : prevIndex - 1
        )
    }

    const goToNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === fileMap!.size - 1 ? 0 : prevIndex + 1
        )
    }

    useEffect(() => {
        if (props.fileMap) {
            setFileMap(props.fileMap)
        } else {
            setFileMap(new Map<number, File>())
        }
    }, [props.fileMap])
    return (
        <div className="main-image-slide">
            <div className="image-carousel">
                <button
                    onClick={goToPreviousImage}
                    className="image-carousel-button"
                    style={{ left: '5px' }}
                >
                    <i className="icon-left-open" />
                </button>
                <button
                    onClick={goToNextImage}
                    className="image-carousel-button"
                    style={{ right: '5px' }}
                >
                    <i className="icon-right-open" />
                </button>
                {fileMap ? (
                    <div
                        className="image-list"
                        style={{
                            transform: `translateX(-${
                                currentImageIndex * 100
                            }%)`,
                        }}
                    >
                        {Array.from(fileMap!.values()).map(
                            (file: File, index: number) => (
                                <div key={index}>
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`Brospo Main Image ${index}`}
                                        onError={(e) => onErrorImg(e)}
                                    />
                                </div>
                            )
                        )}
                    </div>
                ) : (
                    ''
                )}
                <div className="intro-text">
                    <h1>농구복 커스텀 제작 BROSPO</h1>
                    <p>다양한 의류 상품과 커스텀 제작 농구복 판매합니다.</p>
                    <div className="link">
                        <Link to="/product/list" id="product">
                            쇼핑하기
                        </Link>
                        <Link to="/board/inquiry" id="board">
                            문의하기
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainImage
