import React, {useEffect, useState} from "react";

import {getOrderApi} from "@Api/user/order";

import {getModalInstance} from "@Component/common/Modal/ModalProvider";

import {OrderDto} from "@Types/order";
import OrderInfo from "@Component/domains/order/OrderInfo";
import {transFormItem} from "@Service/order/OrderService";
import MainFrame from "@Component/layout/MainFrame";
import {getAdminOrderApi} from "@Api/admin/order";

export const OrderView = (props: { orderCode: string, isAdmin?: boolean }) => {
    const [order, setOrder] = useState<OrderDto.Response>()
    const [orderItemList, setOrderItemList] = useState<OrderDto.TransformedOrder[]>()
    const fetchData = async () => {
        // 첫 번째 API 호출로 order 데이터 설정
        const response = props.isAdmin ? await getAdminOrderApi(props.orderCode) : await getOrderApi(props.orderCode);
        setOrder(response);

        // orderItemList 데이터 변환 후 설정
        if (response?.orderItemList) {
            setOrderItemList(transFormItem(response.orderItemList));
        }
    }

    useEffect(() => {
        fetchData()
    }, [props.orderCode])

    return (
        <MainFrame marginZero={true}>
            <OrderInfo orderDto={order} orderItemList={orderItemList}/>
        </MainFrame>
    )
}

export const AdminOrderView = (props: { orderCode: string }) => {
    const modal = getModalInstance()
    const [orderDTO, setOrderDTO] = useState<any>()

    const fetchData = async () => {
        // setOrderDTO(await getAdminOrderService(props.orderCode))
    }

    useEffect(() => {
        fetchData()
    }, [props.orderCode])

    return (
        <div className="order-view">
            <h2>
                <p>유저정보</p>
                <input
                    className="bg-white-button"
                    type="button"
                    value={'최근주문목록'}
                    onClick={() => {
                        // modal?.popup(<AdminOrderModal orderState={''} user={orderDTO?.order.user} />)
                    }}
                />
            </h2>
            {/*<UserInfoView userInfo={orderDTO?.order.user!}/>*/}

            {/*<AdminOrderInfo orderDTO={orderDTO}/>*/}
        </div>
    )
}


export const OrderStatus = (props: { orderDTO: any | undefined, cancelYn?: boolean }) => {

    const modal = getModalInstance();
    const [orderState, setOrderState] = useState<string>()


    return (
        <div className="order-state-form">
            <h3>
                <p>{props.cancelYn ? '취소 신청' : '교환/반품 신청'}</p>
                <div>
                    <select id="order-status"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                setOrderState(event.target.value)
                            }}
                    >
                        {props.cancelYn ? (<>
                                <option>요청선택</option>
                                <option value={'취소요청'}>취소요청</option>
                            </>) :
                            (<>
                                <option>요청선택</option>
                                <option value={'반품요청'}>반품요청</option>
                                <option value={'반품요청'}>교환요청</option>
                            </>)}

                    </select>
                    <input
                        className={'bg-white-button'}
                        type={'button'}
                        value={'요청하기'}
                        onClick={async () => {
                            if (!orderState) {
                                modal?.alert(() => {
                                }, '알림', '요청 종류를 선택해주세요.')
                                return
                            }
                            if (props.orderDTO) {
                                const updateOrderDTO: any =
                                    {
                                        orderCode: props.orderDTO?.order.orderCode || '',
                                        orderState: orderState,
                                        reason: $('#summernote').summernote('code') || '',
                                    }

                                // if(await updateOrderService(updateOrderDTO)) {
                                //     modal?.alert(()=>{
                                //         modal?.closeModal()
                                //     },'알림',orderState +'에 성공했습니다.')
                                // }else {
                                //     modal?.alert(()=>{window.location.reload()},'알림',orderState +'에 실패했습니다.','주문상태를 다시한번 확인해주세요.')
                                // }
                            }
                        }}
                    />
                </div>
            </h3>

            <label>요청 사유</label>
            {/*<Editor content={props.orderDTO?.order.board?.content || ''} height={150} userSet={true}/>*/}
        </div>
    )
}


export const AdminOrderStatus = (props: { orderDTO: any | undefined }) => {
    const modal = getModalInstance();
    const [order, setOrder] = useState<any>()
    const [alimYn, setAlimYn] = useState('Y') //
    const [partialRefund, setPartialRefund] = useState('N')
    const [orderState, setOrderState] = useState<string>()
    const [cancelPayment, setCancelPayment] = useState<string>()
    const [deliveryCode, setDeliveryCode] = useState<string>()
    const [carrierCode, setCarrier] = useState<string>()

    useEffect(() => {
        setOrder(props.orderDTO?.order)
    }, [])

    useEffect(() => {
        if (orderState == '취소완료') {
            setCancelPayment((parseInt(order?.paymentAmount!) - parseInt(order?.cancelAmount!)).toString())
        } else {
            setCancelPayment(undefined)
        }
        setCarrier(order?.deliveryCode?.split('///')[0])
        setDeliveryCode(order?.deliveryCode?.split('///')[1])
    }, [orderState]);
    const getOptions = () => {
        if (order?.orderState?.includes('취소')) {
            return (
                <>
                    <option>상태선택</option>
                    <option value="취소완료">취소완료</option>
                    <option value="취소반려">취소반려</option>
                    {order?.paymentType == '계좌이체' ? (<option value="입금대기">입금대기</option>) : (
                        <option value="결제완료">결제완료</option>)}
                </>
            )
        } else if (order?.orderState?.includes('교환')) {
            return (
                <>
                    <option>상태선택</option>
                    <option value="교환중">교환중</option>
                    <option value="교환완료">교환완료</option>
                    <option value="교환반려">교환반려</option>
                    <option value="배송완료">배송완료</option>
                </>
            )
        } else if (order?.orderState?.includes('반품')) {
            return (
                <>
                    <option>상태선택</option>
                    <option value="반품중">반품중</option>
                    <option value="반품완료">반품완료</option>
                    <option value="반품반려">반품반려</option>
                    <option value="배송완료">배송완료</option>
                </>
            )
        } else {

            return (
                <>
                    <option>상태선택</option>
                    <option value="준비중">준비중</option>
                    <option value="배송중">배송중</option>
                    <option value="배송완료">배송완료</option>
                    <option value="취소완료">취소완료</option>
                </>
            )
        }
    }

    if (!order) {
        return null
    }
    return (
        <div className="order-state-form">
            <h3>
                <p>상태변경</p>
                <div>
                    {order?.orderState} <i className={'icon-right-open'}/>
                    <select id="order-status"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                setOrderState(event.target.value)

                            }}
                    >{getOptions()}</select>
                    <input
                        className={'bg-white-button'}
                        type={'button'}
                        value={'변경하기'}
                        onClick={async () => {
                            let deliveryCodeTemp
                            if (!orderState) {
                                modal?.alert(() => {
                                }, '알림', '변경상태를 선택해주세요')
                                return
                            }
                            if (orderState.includes('배송') && (!carrierCode || !deliveryCode)) {

                                modal?.alert(() => {
                                }, '알림', '배송번호와 택배사를 선택해주세요.')
                                return
                            }

                            if (orderState == '배송중') {
                                deliveryCodeTemp = carrierCode + '///' + deliveryCode
                            }
                            if (cancelPayment && parseInt(cancelPayment) > parseInt(order?.paymentAmount!) - parseInt(order?.cancelAmount!)) {
                                modal?.alert(() => {
                                }, '알림', '취소가능금액보다 환불금액이 큽니다.')
                                return
                            }
                            // const updateOrderDTO: UpdateOrderDTO = {
                            //     orderCode: order?.orderCode!,
                            //     orderState: orderState,
                            //     answer: $('#summernote').length > 0 ? $('#summernote').summernote('code') || '' : undefined,
                            //     alimYn: alimYn,
                            //     cancelPayment: cancelPayment?.replaceAll(',',''),
                            //     deliveryCode: deliveryCodeTemp
                            // }
                            //
                            //
                            // if (await adminUpdateOrderService(updateOrderDTO)) {
                            //     if(orderState == '취소완료' && order?.paymentType=='계좌이체') {
                            //         modal?.alert(()=>{ modal?.closeModal()},'알림','해당 주문은 계좌이체 주문입니다',`환불계좌 : ${ order.accountNumber!+' '+order.accountHolder!}</br>환불가능금액 : ${parseInt(order.paymentAmount!).toLocaleString()}`)
                            //     }else {
                            //         modal?.alert(()=>{
                            //             modal?.closeModal()
                            //         },'알림','수정에 성공했습니다.')
                            //     }

                            // }else {
                            //     modal?.alert(()=>{},'알림','수정에 실패했습니다.')
                            // }
                        }}
                    />
                </div>
            </h3>
            <div className={"checkbox-area"}>

                {orderState == '취소완료' && order?.paymentType != '계좌이체' && (<><input
                    type="checkbox"
                    id="partial_refund"
                    className="checkbox"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPartialRefund(event.target.checked ? 'Y' : 'N')
                        if (event.target.checked) {
                            setCancelPayment((parseInt(order?.paymentAmount!) - parseInt(order?.cancelAmount!)).toString());
                        } else {
                            setCancelPayment(undefined);
                        }
                    }}
                />
                    <label htmlFor="partial_refund" className="checkbox-label">
                        부분환불
                    </label></>)}
                <input
                    type="checkbox"
                    id="alim_yn"
                    className="checkbox"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAlimYn(event.target.checked ? 'N' : 'Y')
                    }}
                />
                <label htmlFor="alim_yn" className="checkbox-label">
                    알림 미발송
                </label>

            </div>
            {/*{(*/}
            {/*    orderState?.includes('취소') ||*/}
            {/*    orderState?.includes('교환') ||*/}
            {/*    orderState?.includes('반품')*/}
            {/*) && order.board &&(*/}
            {/*    <>*/}
            {/*        <label>사유</label>*/}
            {/*        <div>{stripHTMLTags(order?.board?.content || '')}</div>*/}
            {/*        <label>답변</label>*/}
            {/*        /!*<Editor content={order?.board?.answer?.content || ''} height={150} userSet={true}/>*!/*/}
            {/*    </>*/}
            {/*)}*/}

            {orderState?.includes('배송') && (
                <>
                    <label form="delivery-code">송장번호</label>
                    <select
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            setCarrier(event.target.value)
                        }}
                        defaultValue={order.deliveryCode?.split('///')[0]}
                    >
                        <option>택배사선택</option>
                        {/*{Object.entries(carrierNameMapping).map(*/}
                        {/*    ([key, value]) => (*/}
                        {/*        <option key={key} value={key}>*/}
                        {/*            {value}*/}
                        {/*        </option>*/}
                        {/*    )*/}
                        {/*)}*/}
                    </select>

                    <input
                        id={'delivery-code'}
                        type={'text'}
                        placeholder={'DELIVERY CODE'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setDeliveryCode(event.target.value)
                        }}
                        defaultValue={order.deliveryCode?.split('///')[1]}
                    />
                </>
            )}
            {partialRefund == 'Y' && (
                <>
                    <label
                        form="cancel-amount">취소금액 <span>취소가능금액 : {(parseInt(order?.paymentAmount!) - parseInt(order?.cancelAmount!)).toLocaleString()}</span></label>
                    <input
                        id={'cancel-amount'}
                        type={'text'}
                        placeholder={'CANCEL AMOUNT'}
                        defaultValue={(parseInt(order?.paymentAmount!) - parseInt(order?.cancelAmount!)).toLocaleString()}
                        onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value.replaceAll(',', '')
                            setCancelPayment(value);
                            if (value && !isNaN(parseInt(value)) && !isNaN(parseFloat(value))) {
                                let numberValue = parseFloat(value);
                                event.target.value = numberValue.toLocaleString();
                            } else {
                                event.target.value = '0'
                            }
                        }}
                    />
                </>
            )}
        </div>
    )
}

