import React, {ChangeEvent, SetStateAction, useEffect} from "react";
import styles from './index.module.scss';

interface Props {
    label: string;
    data: any;
    setData: React.Dispatch<SetStateAction<any>>;
    name: string;
    extraClass?: string;
}

const CheckBox: React.FC<Props> = ({ label, data, setData, extraClass, name }) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        // 새 객체를 생성하여 상태를 업데이트
        const updatedData = {
            ...data,
            [name]: checked
        };
        setData(updatedData);
    };

    return (
        <div className={extraClass}>
            <input
                checked={data[name] === 'true' || data[name] === true}
                type="checkbox"
                className={styles.checkbox}
                name={name}
                onChange={handleChange}
                id={name + '-checkbox'}
            />
            <label htmlFor={name + '-checkbox'} className={styles.label}>
                {label}
            </label>
        </div>
    );
}

export default CheckBox;
