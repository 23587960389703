import {CategoryDto} from "@Types/category";

export namespace ProductDto {
    export interface Search {
        page: number;
        rowSize: number;
        categoryId?: string;
        primaryCategory?: string;
        productName?: string;
        sortMode: string;
        isAdmin?: boolean;
    }

    export interface Response {
        productId?: string;
        category?: CategoryDto.Response;
        name?: string;
        price?: string;
        deliveryFee?: string;
        deliveryFeeThreshold? :string;
        size?: string;
        productCode?: string;
        productDescription?: string;
        reviewCount?: string;
        reviewRating?: string;
        imageUrl?: string[];
    }

    export interface Save {
        productId?: string;
        categoryId?: string;
        name?: string;
        price?: string;
        deliveryFee?: string;
        deliveryFeeThreshold?:string;
        size?: string;
        productCode?: string;
        productDescription?: string;
        fileList?: FormData
    }

    export interface Duplication {
        productId?: number;
        productCode?: string;
    }

    export interface Page {
        content?: Response[];
        totalElements?: number;
    }
}


export const mapResponseToSave = (response: ProductDto.Response): ProductDto.Save => {
    return {
        productId: response.productId,
        categoryId: response.category?.categoryId, // Assuming that `CategoryDto.Response` is compatible
        name: response.name,
        price: Number(response.price).toLocaleString() ,
        deliveryFee:  Number(response.deliveryFee).toLocaleString() ,
        deliveryFeeThreshold: Number(response.deliveryFeeThreshold).toLocaleString(),
        size: response.size,
        productCode: response.productCode,
        productDescription: response.productDescription
        // fileList: undefined // Adjust according to your needs
    };
};