import React, {createContext, ReactNode, useEffect, useState,} from 'react'
import Modal from './Modal'

interface ModalConfig {
    id: number
    isOpen: boolean
    onClose?: () => void
    onConfirm?: (input: string) => void
    title?: string
    message1?: string
    message2?: string
    modalMode?: number
    component?: ReactNode
    maxWidth?:string
}

interface ModalContextType {
    alert: (
        onClose: () => void,
        title: string,
        message1: string,
        message2?: string,
    ) => void
    confirm: (
        onConfirm: () => void,
        title: string,
        message1: string,
        message2?: string,
    ) => void
    popup: (component: ReactNode, maxWidth?: string, onClose?: () => void) => void
    prompt: (
        onConfirm: (input: string) => void,
        onClose: () => void,
        title: string,
        message1: string,
        message2?: string
    ) => void
    closeModal: () => void
    getLastIndex: () => number
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)
let modalInstance: ModalContextType | null = null

export const ModalProvider = () => {
    const [modals, setModals] = useState<ModalConfig[]>([])
    const [inputValue, setInputValue] = useState<string>('')

    const showModal = (config: Omit<ModalConfig, 'id'>) => {
        const newModal = {
            id: Date.now(),
            ...config,
        }
        setModals((prevModals) => {
            if (prevModals.length === 0) {
                document.body.style.overflow = 'hidden'
            }
            return [...prevModals, newModal]
        })
    }

    const alert = (
        onClose: () => void,
        title: string,
        message1: string,
        message2?: string
    ) => {
        setTimeout(() => {
            showModal({
                isOpen: true,
                onClose,
                title,
                message1,
                message2: message2 || '',
                modalMode: 0,
            })
        }, 1)
    }

    const confirm = (
        onConfirm: () => void,
        title: string,
        message1: string,
        message2?: string,
        width?: string,
        height?: string
    ) => {
        setTimeout(() => {
            showModal({
                isOpen: true,
                onConfirm,
                title,
                message1,
                message2: message2 || '',
                modalMode: 1,
            })
        }, 1)
    }

    const popup = (component: ReactNode, maxWidth?: string, onClose?: () => void) => {

        setTimeout(() => {
            showModal({
                isOpen: true,
                component,
                onConfirm: () => { },
                onClose: onClose,
                modalMode: 2,
                maxWidth:  maxWidth || '800px',
            })
        }, 1)
    }

    const prompt = (
        onConfirm: (input: string) => void,
        onClose: () => void,
        title: string,
        message1: string,
        message2?: string,
    ) => {
        setTimeout(() => {
            showModal({
                isOpen: true,
                onConfirm,
                onClose,
                title,
                message1,
                message2: message2 || '',
                modalMode: 3
            })
        }, 1)
    }

    const handleClose = (index: number) => {
        setModals((prevModals) => {
            const updatedModals = [...prevModals];

            // 인덱스가 -1인 경우 배열의 마지막 모달을 제거
            const modalIndex = index === -1 ? updatedModals.length - 1 : index;
            const modalToClose = updatedModals[modalIndex];

            if (modalToClose?.onClose) {
                modalToClose.onClose();
            }

            updatedModals.splice(modalIndex, 1);

            if (updatedModals.length === 0) {
                document.body.style.overflow = 'unset';
            }

            return updatedModals;
        });
    };


    const handleConfirm = (index: number, inputValue: string) => {
        const modal = modals[index]
        if (modal?.onConfirm) {
            modal.onConfirm(inputValue || '')
            setInputValue('')
        }
        handleClose(index)
    }

    const closeModal = () => {
            handleClose(-1)
    }

    const getLastIndex = () => {
        return modals.length - 1;
    }

    useEffect(() => {
        modalInstance = {
            alert,
            confirm,
            popup,
            prompt,
            closeModal,
            getLastIndex
        }
        return () => {
            modalInstance = null
        }
    }, [modals, inputValue])

    return (
        <ModalContext.Provider value={{ alert, confirm, popup, prompt, closeModal ,getLastIndex }}>
            {modals.map((modal, index) => (
                <Modal
                    key={modal.id}
                    isOpen={modal.isOpen}
                    title={modal.title || ''}
                    message1={modal.message1 || ''}
                    message2={modal.message2 || ''}
                    onClose={() => handleClose(index)}
                    onConfirm={() => handleConfirm(index, inputValue)}
                    modalMode={modal.modalMode}
                    component={modal.component}
                    maxWidth={modal.maxWidth}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            ))}
        </ModalContext.Provider>
    )
}

// export const useModal = (): ModalContextType => {
//     const context = useContext(ModalContext)
//     if (context === undefined) {
//         throw new Error('useModal must be used within a ModalProvider')
//     }
//     return context
// }

export const getModalInstance = (): ModalContextType | null => {
    return modalInstance
}


