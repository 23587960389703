
// Default setting for AddressDto.Save
import {AddressDto} from "@Types/address/addressDto";
import {UserDto} from "@Types/user";

export const createAddressSaveDto = (userInfo?:UserDto.Info): AddressDto.Save => ({
    addressId:  '',
    address: '',
    mainAddress:false,
    recipientName: '',
    recipientContact: '',
    addressNickName: '집',
    deliveryRequest: '',
    deliveryRequestDetail: ''
});

// Default setting for AddressDto.Response
export const createAddressResponseDto = (): AddressDto.Response => ({
    addressId:  '',
    address: '',
    mainAddress: false,
    recipientName: '',
    recipientContact: '',
    addressNickName: '',
    deliveryRequest: ''
});
