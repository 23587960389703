import styles from "@Css/product/productItem.module.scss";
import {addWish} from "@Service/wish/WishService";
import {shareLink} from "@Util/viewUtils";
import React from "react";
import {ProductDto} from "@Types/product";
import ReviewRating from "@Component/domains/board/ReviewRating";


export const ProductIcon = (props: { product?: ProductDto.Response, rating?: boolean }) => {

    return <div className={styles.productIcon}>
        {props.rating &&<div>
            <ReviewRating data={props.product} name={'reviewRating'}/>({props.product?.reviewCount})</div>}
        <i
            className={`product-wish-check-${props.product?.productId} icon-heart-empty`}
            onClick={(e) => {
                addWish(e, props.product?.productId!)
            }}
        ></i>
        <i className="icon-upload" onClick={() => shareLink(props?.product?.productId!)}></i>
    </div>
}