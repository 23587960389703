import React, { useEffect } from 'react'
import ReviewList from '@Component/domains/board/List/ReviewList'
import MainFrame from '@Component/layout/MainFrame'

const AdminReviewPage = () => {
    return (
        <MainFrame
            title={'상품리뷰'}
            documentTitle={'상품리뷰'}
            maxWidth={1100}
        >
            <ReviewList isAdmin={true} />
        </MainFrame>
    )
}

export default AdminReviewPage
