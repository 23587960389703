import { FormFieldProps } from 'components/layout/FormField'

export const findForm: FormFieldProps[] = [
    {
        label: '전화번호',
        placeholder: 'EMAIL',
        inputType: 'password',
        name: 'password',
    },
    {
        label: '이메일',
        placeholder: 'EMAIL',
        inputType: 'password',
        name: 'password',
    },
    {
        label: '이름',
        placeholder: 'EMAIL',
        inputType: 'password',
        name: 'password',
    },
]
