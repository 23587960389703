import styles from './index.module.scss';

export interface HeaderOption {
    name: string;
    rowCount: number;
    width?: string | number;
    hidden?: boolean;
}

interface HeaderProps {
    options: HeaderOption[];
}

const Header: React.FC<HeaderProps> = ({ options }) => {
    return (
        <tr>
            {options.map((option, index) => (
                <th
                    key={index}
                    colSpan={option.rowCount}
                    style={{ width: option.width }}
                    className={option.hidden ? styles.hidden : ''}
                >
                    {option.name}
                </th>
            ))}
        </tr>
    );
};

export default Header;
