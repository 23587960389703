import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {deleteRequest, getRequest, postRequest} from "@Api/Api";
import {ProductDto} from "@Types/product";

export const insertWishApi = async (productId: string) => {
    const modal = getModalInstance()
    try {
        const httpSuccess: HttpSuccess = {

        }
        const httpAlert: HttpAlert = {
            401: () => {
                modal?.confirm(
                    () => {
                        window.location.href = '/user/login'
                    },
                    '알림',
                    '추가를 위해 로그인이 필요합니다.',
                    '로그인 하시겠습니까?'
                )
            },
        }
         return await postRequest('/wish/'+productId, httpSuccess, httpAlert)
    } catch (e) {
        console.log(e)
    }
}

export const getWishListApi = async () => {
    try {

        return await getRequest<ProductDto.Response[]>('/wish')
    } catch (e) {
        console.log(e)
    }
}

export const deleteWishApi = async (productId: string) => {
    try {
        return await deleteRequest('/wish/'+productId)
    } catch (e) {
        console.log(e)
    }
}
