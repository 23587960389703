import React, {useEffect, useRef} from 'react';
import styles from './ImageDrop.module.scss';
import {convertToPng, isPng} from '@Util/imageUtils';
import {getModalInstance} from '@Component/common/Modal/ModalProvider';

interface DropAreaProps {
    fileMap: Map<number, File>;
    setFileMap: React.Dispatch<React.SetStateAction<Map<number, File>>>;
    maxFiles?: number; // Maximum number of files allowed
}

const DropArea = ({ fileMap, setFileMap, maxFiles = 5 }: DropAreaProps) => {
    const modal = getModalInstance();
    const galleryRef = useRef<HTMLDivElement>(null);
    const hoverViewRef = useRef<HTMLDivElement>(null);

    const addFiles = async (files: FileList) => {
        let newFileMap = new Map(fileMap);
        let currentFilesCount = newFileMap.size;

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (!/^image\//.test(file.type)) {
                modal?.alert(
                    () => {},
                    '알림',
                    '이미지만 업로드 가능합니다.',
                    ''
                );
                continue;
            }

            // PNG로 변환
            const processedFile = isPng(file) ? file : await convertToPng(file);

            if (currentFilesCount >= maxFiles) {
                modal?.confirm(
                    () => {
                        const lastKey = Math.max(...newFileMap.keys());
                        newFileMap.set(lastKey, processedFile);

                        setFileMap(newFileMap);
                    },
                    '확인',
                    '이미지 최대 개수를 초과했습니다.',
                    '마지막 이미지를 교체하시겠습니까?'
                );
            } else {
                const newKey = newFileMap.size > 0 ? Math.max(...newFileMap.keys()) + 1 : 0;
                newFileMap.set(newKey, processedFile);
                currentFilesCount++;
                setFileMap(newFileMap);
            }
        }
    };

    const delFile = (num: number) => {
        const newFileMap = new Map(fileMap);
        newFileMap.delete(num);
        setFileMap(newFileMap);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        addFiles(event.dataTransfer.files);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        // Initialize file input from fileMap if provided
        const dataTransfer = new DataTransfer();
        fileMap.forEach((file) => {
            dataTransfer.items.add(file);
        });
        const filesList = dataTransfer.files;
        addFiles(filesList);
    }, []); // Empty dependency ensures this runs only once on mount

    return (
        <div
            className={styles.dropArea}
            onDragEnter={handleDragOver}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            <p>여기 이미지를 놔두세요</p>
            <input
                type="file"
                id="fileElem"
                multiple
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => addFiles(e.target.files!)}
            />
            <label className={styles.selectImage} htmlFor="fileElem">
                파일 선택하기
            </label>
            <div className={styles.gallery} ref={galleryRef}>
                {Array.from(fileMap.entries()).map(([num, file]) => (
                    <div key={num} className={styles.galleryItem} data-num={num}>
                        <img
                            src={URL.createObjectURL(file)}
                            alt={`image-${num}`}
                            onLoad={(e) =>
                                URL.revokeObjectURL((e.target as HTMLImageElement).src)
                            }
                        />
                        <i
                            className={`${styles.removeImage} icon-trash`}
                            onClick={() => delFile(num)}
                        ></i>
                    </div>
                ))}
            </div>
            <div className={styles.hoverView} ref={hoverViewRef}></div>
        </div>
    );
};

export default DropArea;
