import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import React, { useEffect, useState } from 'react'
import {
    carrierNameMapping,
    createOrderUpdateDto,
    OrderDto,
} from '@Types/order'
import { updateOrderApi } from '@Api/user/order'

import { decodeHTML } from '@Util/stringUtils'
import { AddressDto } from '@Types/address'
import { DeliveryTracker } from '@Component/common/DeliveryTracker/DeliveryTracker'
import MainFrame from '@Component/layout/MainFrame'

import styles from './index.module.scss'
import { ProductListItem } from '@Component/domains/product/ProductList'
import RequestItem from '@Component/domains/order/OrderInfo/RequestItem'
import Button from '@Component/common/Input/Button'
import {AddressForm} from "@Component/domains/address/List";

interface Props {
    orderDto?: OrderDto.Response
    setOrderDto?: React.Dispatch<
        React.SetStateAction<OrderDto.Response | undefined>
    >
    orderItemList?: OrderDto.TransformedOrder[]
    isAdmin?:boolean
}

export const OrderInfo: React.FC<Props> = ({
    orderDto,
    orderItemList,
    setOrderDto,
    isAdmin
}) => {
    const modal = getModalInstance()
    const [saveDto, setSaveDto] = useState<OrderDto.UserUpdate>(
        createOrderUpdateDto(orderDto)
    )
    const [addressDto, setAddressDto] = useState<AddressDto.Response>()
    const [moreLoad, setMoreLoad] = useState<boolean>(false)
    const updateAddress = async () => {
        const updatedSaveDto = {
            ...saveDto,
            address: addressDto,
        }
        setSaveDto(updatedSaveDto)
        await updateOrderApi(updatedSaveDto)
    }

    useEffect(() => {
        if (addressDto) {
            updateAddress()
            if (setOrderDto)
                setOrderDto({
                    ...orderDto,
                    ...addressDto,
                })
        }
    }, [addressDto])

    return (
        <div>
            {orderDto?.orderState != '주문대기' && (
                <React.Fragment>
                    <MainFrame
                        title={
                            <>
                                {orderDto?.orderState}{' '}
                                <span>{orderDto?.orderCode}</span>
                            </>
                        }
                        hideSiteName={true}
                        className={styles.info}
                        action={[
                            {
                                name: '교환/환불 신청',
                                onClick: () => {
                                    setMoreLoad(!moreLoad)
                                },
                            },
                        ]}
                    >
                        <div className={styles.request}>
                            {orderDto?.orderRequestList
                                ?.slice(
                                    0,
                                    moreLoad
                                        ? orderDto.orderRequestList.length
                                        : 5
                                )
                                .map((item, index) => (
                                    <RequestItem request={item} key={index} />
                                ))}
                        </div>
                        {orderDto?.orderRequestList?.length! > 5 && (
                            <Button
                                name={moreLoad ? '닫기' : '전체내역보기'}
                                onClick={() => {
                                    setMoreLoad(!moreLoad)
                                }}
                            />
                        )}
                    </MainFrame>
                    <MainFrame
                        className={styles.infoFlex + ' ' + styles.info}
                        title={'결제정보'}
                        hideSiteName={true}
                    >
                        <div>
                            <p>결제수단</p>
                            <span>{orderDto?.paymentType}</span>
                        </div>
                        {orderDto?.paymentType == '계좌이체' && (
                            <>
                                <div>
                                    <p>입금계좌안내</p>
                                    <span>신한 110-454-160504 손영욱</span>
                                </div>
                                <div>
                                    <p>송금계좌</p>
                                    <span>
                                        {orderDto?.accountCode}{' '}
                                        {orderDto?.accountHolder}{' '}
                                        {orderDto?.accountNumber}
                                    </span>
                                </div>
                            </>
                        )}
                        <div>
                            <p>결제금액</p>
                            <span>
                                {orderDto?.paymentAmount?.toLocaleString()} 원
                            </span>
                        </div>
                    </MainFrame>
                </React.Fragment>
            )}
            <MainFrame
                className={styles.infoFlex + ' ' + styles.info}
                hideSiteName={true}
                title={'배송정보'}
                action={[
                    {
                        name: '배송 정보 수정',
                        onClick: () => {
                            modal?.popup(
                                <AddressForm setAddress={setAddressDto} />
                            )
                        },
                    },
                ]}
            >
                {addressDto || orderDto?.recipientName ? (
                    <>
                        <div>
                            <p>수령인</p>{' '}
                            <span>
                                {addressDto?.recipientName ||
                                    orderDto?.recipientName}
                            </span>
                        </div>
                        <div>
                            <p> 전화번호</p>
                            <span>
                                {addressDto?.recipientContact ||
                                    orderDto?.recipientContact}
                            </span>
                        </div>
                        <div>
                            <p>주소</p>
                            <span>
                                {decodeHTML(
                                    addressDto
                                        ? `${addressDto?.address} ${addressDto?.addressDetail}`
                                        : `${orderDto?.address} ${orderDto?.addressDetail}`
                                )}
                            </span>
                        </div>
                        <div>
                            <p>배송요청사항</p>
                            <span>
                                {addressDto?.deliveryRequest ||
                                    orderDto?.deliveryRequest}
                            </span>
                        </div>
                        {orderDto?.deliveryCode && (
                            <div>
                                <p>배송번호</p>
                                <span
                                    className={
                                        orderDto?.deliveryCode ? 'link' : ''
                                    }
                                    onClick={() => {
                                        if (orderDto?.deliveryCode) {
                                            modal?.popup(
                                                <DeliveryTracker
                                                    deliveryCode={
                                                        orderDto.deliveryCode!
                                                    }
                                                    deliveryNumber={
                                                      orderDto.deliveryNumber!
                                                    }
                                                />
                                            )
                                        }
                                    }}
                                >
                                    {`${
                                        carrierNameMapping[
                                            orderDto.deliveryCode
                                        ]
                                    } ${orderDto?.deliveryNumber}`}
                                </span>
                            </div>
                        )}
                    </>
                ) : (
                    <div className={'empty-view'}>배송지를 선택해주세요</div>
                )}
            </MainFrame>
            <MainFrame
                className={styles.info + ' ' + styles.order}
                title={'주문목록'}
                hideSiteName={true}
            >
                {orderItemList?.map((orderItem, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            modal?.confirm(
                                () => {},
                                '확인',
                                '상품을 확인하시겠습니까?',
                                '페이지로 이동합니다.'
                            )
                        }}
                    >
                        <ProductListItem
                            product={orderItem.product!}
                            mode={'order'}
                        />
                        <div className={styles.productCount}>
                            <div>수량 :</div>
                            {orderItem.sizes?.map((size, index) => (
                                <div key={index}>
                                    {size.size?.toUpperCase()} / {size.count}
                                    {index != orderItem.sizes.length - 1 &&
                                        ' ,'}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </MainFrame>
        </div>
    )
}

export default OrderInfo
