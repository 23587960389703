import InquiryList from '@Component/domains/board/List/InquiryList'
import ReviewList from '@Component/domains/board/List/ReviewList'
import MainFrame from '@Component/layout/MainFrame'
import { useEffect, useState } from 'react'
import { UserDto } from '@Types/user'
import { checkUserInfo } from '@Service/user/UserService'

const MypageBoard = () => {
    const [userInfo, setUserInfo] = useState<UserDto.Info>()

    useEffect(() => {
        checkUserInfo(setUserInfo, true)
    }, [])

    if (!userInfo) {
        return <></>
    }
    return (
        <MainFrame>
            <MainFrame title={'내가쓴 문의'}>
                <InquiryList userId={userInfo?.userId} />
            </MainFrame>
            <MainFrame title={'내가쓴 리뷰'}>
                <ReviewList userId={userInfo?.userId} />
            </MainFrame>
        </MainFrame>
    )
}

export default MypageBoard
