import React, { useEffect } from 'react'

import NoticeList from '@Component/domains/board/List/NoticeList'
import MainFrame from '@Component/layout/MainFrame'

function AdminNoticePage() {
    return (
        <MainFrame
            title={'공지사항'}
            documentTitle={'공지사항'}
            maxWidth={1000}
        >
            <NoticeList isAdmin={true} />
        </MainFrame>
    )
}

export default AdminNoticePage
