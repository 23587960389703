import {useEffect} from "react";
import {getModalInstance} from "@Component/common/Modal/ModalProvider";
import {getUserInfo} from "@Api/user/account";

const CheckAdmin = () =>{

     const  fetchData = async () => {
         const modal =getModalInstance()
         const userInfo = await getUserInfo()
         if (window.location.href.includes('admin') && (!userInfo || userInfo.role == 'USER')) {
             modal?.alert(() => {
                 window.location.href = '/user/login'
             }, '알림', '관리자 로그인이 필요합니다.', '로그인페이지로 이동합니다.')
         }
     }

    useEffect(() => {
        fetchData()
    }, []);
    return null
}

export default CheckAdmin