import {useLocation, useNavigate} from 'react-router-dom'
import './ProductSearch.css'
import {useEffect} from 'react'

export const ProductSearch = () => {
    const location = useLocation()
    const navigate = useNavigate()
    function searchProduct(): void {
        const title = document.getElementById('schProduct') as HTMLInputElement

        if (location.pathname.includes('admin')) {
            navigate(`/admin/product/list?title=${title.value}`)
        } else {
            navigate(`/product/list?title=${title.value}`)
        }
        closeProductSearch()
    }

    const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            const searchProductElement = document.querySelector(
                '.search-product'
            ) as HTMLElement
            if (
                searchProductElement &&
                searchProductElement.style.transform ===
                    'translateY(0%) translateX(50%)'
            ) {
                searchProduct()
            }
        }
    }

    useEffect(() => {
        const inputElement = document.getElementById('schProduct')
        if (inputElement) {
            inputElement.addEventListener('keydown', handleKeyDown)
        }
        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [])

    return (
        <>
            <div className="search-product">
                <div className="close-button">
                    <i className="icon-cancel" onClick={closeProductSearch}></i>
                </div>
                <h3>상품 이름을 입력해주세요.</h3>
                <div>
                    <input
                        type="text"
                        id="schProduct"
                        placeholder="PRODUCT NAME"
                    />
                    <i className="icon-search" onClick={searchProduct}></i>
                </div>
            </div>
            <div
                className="search-product-bg"
                onClick={closeProductSearch}
            ></div>
        </>
    )
}
export const openProductSearch = () => {
    const searchProduct = document.querySelector(
        '.search-product'
    ) as HTMLElement
    if (searchProduct) {
        searchProduct.style.transform = 'translateY(0%) translateX(50%)'
    }

    const searchProductBg = document.querySelector(
        '.search-product-bg'
    ) as HTMLElement

    if (searchProductBg) {
        searchProductBg.style.display = 'block'
    }
}

export const closeProductSearch = () => {
    const searchProduct = document.querySelector(
        '.search-product'
    ) as HTMLElement
    if (searchProduct) {
        searchProduct.style.transform = 'translateY(-100%) translateX(50%)'
    }

    const searchProductBg = document.querySelector(
        '.search-product-bg'
    ) as HTMLElement

    if (searchProductBg) {
        searchProductBg.style.display = 'none'
    }

    ;(document.getElementById('schProduct') as HTMLInputElement).value = ''
}
