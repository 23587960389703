import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {CategoryList} from "@Types/category";
import styles from '@Css/product/productList.module.scss'
import {getCategoryList} from "@Api/cmm/CmmService";

export const SecondaryCategoryBar = () => {
    const adminUrl = window.location.href.includes('admin') ? '/admin' : ''
    const [categoryList, setCategoryList] = useState<CategoryList>({})

    const { primaryCategory, secondaryCategory } = useParams<{
        primaryCategory: string
        secondaryCategory: string
    }>()

    const  fetchData = async () => {
        setCategoryList(await getCategoryList())
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (secondaryCategory) {
        return (
            <React.Fragment>
                <div className={styles.productCategoryContain}>
                    <Link to={adminUrl + '/product/list/' + primaryCategory}>
                        전체보기
                    </Link>
                    {Object.values(categoryList).map((categories) =>
                        categories
                            .filter(
                                (category) =>
                                    category.primaryName === primaryCategory
                            )
                            .map((category, primaryIndex) => (
                                <Link
                                    key={primaryIndex}
                                    to={
                                        adminUrl +
                                        '/product/list/' +
                                        category.primaryName +
                                        '/' +
                                        category.secondaryName
                                    }
                                    id={
                                        secondaryCategory ==
                                        category.secondaryName
                                            ? 'current'
                                            : ''
                                    }
                                >
                                    {category.secondaryName}
                                </Link>
                            ))
                    )}
                </div>
            </React.Fragment>
        )
    } else {
        return null
    }
}
