// import { AdminOrderList } from '@Component/domains/order/OrderList'

import {TabBar} from "@Component/layout/TabBar";
import MainFrame from "@Component/layout/MainFrame";
import {AdminOrderList} from "@Component/domains/order/OrderList";

export const AdminOrderPage = () => {
    const orderPageTabOptions = [
        { label: '주문', link: '/admin/order?orderType=주문' },
        { label: '교환', link: '/admin/order?orderType=교환' },
        { label: '반품', link: '/admin/order?orderType=반품' },
        { label: '취소', link: '/admin/order?orderType=취소' }]
    return (
        <MainFrame >
             <TabBar options={orderPageTabOptions} />
             <AdminOrderList />
        </MainFrame>
    )
}
