import {UserDto} from './userDto';

export const createUserInfoDto = (): UserDto.Info => ({
    userId: '',
    email: '',
    userName: '',
    phoneNumber: '',
    userIp: '',
    role: ''
});

export const createUserJoinDto = (): UserDto.Join => ({
    loginId: '',
    password: '',
    email: '',
    userName: '',
    phoneNumber: '',
    agreement: '',
    privacy: ''
});

export const createUserLoginDto = (): UserDto.Login => ({
    loginId: '',
    password: ''
});

export const createUserUpdateDto = (): UserDto.Update => ({
    password: '',
    email: '',
    userName: '',
    phoneNumber: ''
});

export const createUserFindDto = (): UserDto.Find => ({
    password: '',
    email: '',
    userName: '',
    phoneNumber: ''
});

export const createUserResetDto = (): UserDto.Reset => ({
    password: '',
    loginId: '',
    authToken: ''
});

export const createUserDuplicationDto = (): UserDto.Duplication => ({
    loginId: '',
    email: '',
    userIp: ''
});

export const createUserTokenDto = (): UserDto.Token => ({
    userId: 0,
    accToken: '',
    refToken: '',
    validType:  'VALID'
});
