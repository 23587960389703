import React, {useEffect} from 'react'

import NoticeList from "@Component/domains/board/List/NoticeList";
import MainFrame from "@Component/layout/MainFrame";

function NoticePage() {
    useEffect(() => {
        document.title = 'BROSPO 공지사항'
    }, [])

    return (
        <MainFrame title={'공지사항'} documentTitle={'공지사항'}  maxWidth={1000}>
            <NoticeList isAdmin={false} />
        </MainFrame>
    )
}

export default NoticePage
