// DefaultSettings.ts

import {ProductDto} from './productDto';
import {createCategoryResponseDto} from "@Types/category";


export const createProductResponseDto = (): ProductDto.Response => ({
    productId: '',
    category: createCategoryResponseDto(), // Create this function if needed
    name: '',
    price: '',
    deliveryFee: '',
    size: '',
    productCode: '',
    productDescription: '',
    deliveryFeeThreshold: '',
    reviewCount: '',
    reviewRating: '',
    imageUrl: []
});

export const createProductSaveDto= (): ProductDto.Save => ({
    productId: '',
    categoryId: '',
    name: '',
    price: '',
    deliveryFee: '',
    deliveryFeeThreshold :'',
    size: '',
    productCode: '',
    productDescription: '',
    // fileList: []
});

export const createProductDuplicationDto = (): ProductDto.Duplication => ({
    productId: 0,
    productCode: ''
});
