import React, {useEffect, useState} from "react"

import {MainImageService} from "@Api/cmm/CmmService";
import {getFileMap} from "@Util/imageUtils";
import MainImage from "@Component/domains/cmm/MainImage/MainImage";
import ProductListPage from "@Page/user/product/ProcutListPage";
import ReviewList from "@Component/domains/board/List/ReviewList";
import {CategoryList} from "@Types/category/categoryDto";
import MainFrame from "@Component/layout/MainFrame";

function IndexPage() {
    const [categoryList, setCategoryList] = useState<CategoryList>({})

    const [fileMap, setFileMap] = useState<Map<number, File>>()

    const fetchFileMap = async () => {
        const imageList = await MainImageService()
        if (imageList) {
            setFileMap(await getFileMap(imageList))
        }
    }

    useEffect(() => {
        fetchFileMap()

        document.title = 'BROSPO'
    }, [])

    return (
        <>
            <MainImage fileMap={fileMap}/>
            <ProductListPage/>
            <MainFrame title={'상품리뷰'} documentTitle={'상품리뷰'}  maxWidth={1400} >
                <ReviewList/>
            </MainFrame>
        </>
    )
}

export default IndexPage
