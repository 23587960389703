// OrderRequest.ts
import {UserDto} from '@Types/user'
import {ProductDto} from '@Types/product'
import {BoardDto} from '@Types/board'
import {AddressDto, createAddressResponseDto,} from '@Types/address'
import {subMonths} from "date-fns";

// OrderDto.ts
export namespace OrderDto {
    export interface Response {
        orderId?: number
        user?: UserDto.Info
        orderCode?: string
        orderName?: string
        orderState?: string
        orderDate?: string // ISO 형식의 날짜 문자열
        paymentType?: string
        paymentAmount?: number
        cancelAmount?: number
        accountHolder?: string
        accountNumber?: string
        accountCode?: string
        deliveryCode?: string
        deliveryNumber?: string
        address?: string
        addressDetail?: string
        recipientName?: string
        recipientContact?: string
        deliveryRequest?: string
        orderRequestList?: Request[]
        orderItemList?: Item[]
    }

    export interface Page {
        content?: Response[];
        totalElements?: number;
    }

    export interface Item {
        product?: ProductDto.Response
        size?: string
        count?: number
    }

    export interface Request {
        orderRequestId?: number
        board?: BoardDto.Response
        status?: string
        stepDate?: string // ISO 형식의 날짜 문자열
    }

    export interface Search {
        page?: number
        rowSize?: number
        startDate?: Date | string // ISO 형식의 날짜 문자열
        endDate?: Date | string// ISO 형식의 날짜 문자열
        userId?: number
        orderCode?: string
        orderState?: string
        paymentType?: string
        deliveryNumber?: string
        userName?: string
        userPhone?: string
    }

    export interface UserUpdate {
        orderCode?: string
        orderState?: string
        reason?: string
        address?: AddressDto.Response
    }

    export interface AdminUpdate {
        orderCode?: string
        orderName?: string
        orderState?: string
        paymentType?: string
        paymentAmount?: number
        cancelAmount?: number
        accountHolder?: string
        accountNumber?: string
        deliveryCode?: string
        address?: string
        recipientName?: string
        recipientContact?: string
        deliveryRequest?: string
        answer?: string
        alimYn?: string
    }

    export interface AdminInsert {
        orderName?: string
        orderState?: string
        orderDate?: Date | string // ISO 형식의 날짜 문자열
        paymentType?: string
        paymentAmount?: string
        accountCode?:string
        accountHolder?: string
        accountNumber?: string
        deliveryCode?: string
        deliveryNumber?: string
        address?: string
        recipientName?: string
        recipientContact?: string
        deliveryRequest?: string
    }

    export interface Count {
        completeOrderCount?: number
        inDeliveryCount?: number
        deliveredCount?: number
        exchangeOrRefundCount?: number
        preparationCount?: number
    }

    export interface PaymentInfo {
        transactionType?: string
        txId?: string
        paymentId?: string
        code?: string
        message?: string
        accountHolder?: string
        accountNumber?: string
    }

    export interface TransformedOrder {
        product: ProductDto.Response
        sizes: OrderDto.Item[]
    }
}

export interface DeliveryState {
    name: string
    description: string
    time: Date
}

export  const createOrderInsertDto= (
): OrderDto.AdminInsert => ({
   orderDate: new Date()
})
export const createOrderUpdateDto = (
    order?: OrderDto.Response
): OrderDto.UserUpdate => ({
    orderCode: order?.orderCode,
    orderState: order?.orderState,
    reason: '',
    address: createAddressResponseDto(),
})

export const createPaymentDto = (
    orderDto?: OrderDto.Response
): OrderDto.PaymentInfo => ({
    transactionType: '',
    txId: '',
    paymentId: orderDto?.orderCode,
    message: '',
    accountHolder: '',
    accountNumber: '',
})


export const createSearchDto = (): OrderDto.Search => {
    const endDate = new Date()
    endDate.setHours(23, 59, 59, 999)

    return {
        orderCode: '',
        orderState: '',
        paymentType: '',
        deliveryNumber: '',
        userName: '',
        userPhone : '',
        startDate: subMonths(
            new Date(),
            3
        ),
        endDate:endDate
    }
}