import React, { useEffect } from 'react'

import ReviewList from '@Component/domains/board/List/ReviewList'
import InquiryList from '@Component/domains/board/List/InquiryList'
import MainFrame from '@Component/layout/MainFrame'

export const AdminUnprocessedPage = () => {
    return (
        <MainFrame title={'미답변 게시글'} documentTitle="미답변 게시글">
            <MainFrame title={'상품 리뷰'} hideSiteName={true}>
                <ReviewList isAdmin={true} noAnswer={true} />
            </MainFrame>
            <MainFrame title={'상품 문의'} hideSiteName={true}>
                <InquiryList isAdmin={true} noAnswer={true} />
            </MainFrame>
        </MainFrame>
    )
}

export default AdminUnprocessedPage
