import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {CategoryList} from "@Types/category";
import styles from '@Css/product/productList.module.scss'
import {getCategoryList} from "@Api/cmm/CmmService";

export const PrimaryCategoryBar = () => {
    const adminUrl = window.location.href.includes('admin') ? '/admin' : ''
    const [categoryList, setCategoryList] = useState<CategoryList>({})
    const { primaryCategory, secondaryCategory } = useParams<{
        primaryCategory: string
        secondaryCategory: string
    }>()

    const  fetchData = async () => {
        setCategoryList(await getCategoryList())
    }

    useEffect(() => {
        fetchData()
    }, [])

    if (!secondaryCategory) {
        return (
            <React.Fragment>
                <div className={styles.productCategoryContain}>
                    <Link
                        to={adminUrl + '/product/list/'}
                        id={primaryCategory ? '' : 'current'}
                    >
                        전체상품
                    </Link>
                    {adminUrl && (
                        <Link
                            to={adminUrl + '/product/list/비공개'}
                            id={primaryCategory == '비공개' ? 'current' : ''}
                        >
                            비공개
                        </Link>
                    )}
                    {Object.values(categoryList).map(
                        (categories, index) => (
                            <Link
                                to={
                                    adminUrl +
                                    '/product/list/' +
                                    categories.at(0)!.primaryName +
                                    '/all'
                                }
                                key={index}
                                id={
                                    primaryCategory ==
                                    categories.at(0)!.primaryName
                                        ? 'current'
                                        : ''
                                }
                            >
                                {categories.at(0)!.primaryName}
                            </Link>
                        )
                    )}
                </div>
            </React.Fragment>
        )
    } else {
        return null
    }
}
