import {OrderDto} from "@Types/order";
import {formatDateToString} from "@Util/stringUtils";
import React from "react";
import {formatDate} from "@Util/dateUtile";


interface Props {
    request: OrderDto.Request
}

const RequestItem: React.FC<Props> = ({request}) => {

    return <div>
        <h4>
            {request.status}<span>{formatDate(request?.stepDate)}</span><a>●</a>
        </h4>
        <p></p>
    </div>
}

export default RequestItem