import React, { useEffect, useState } from 'react';
import { OrderDto } from "@Types/order";
import { ProductDto } from "@Types/product";
import styles from './index.module.scss';
import { deleteCartApi, insertCartApi } from "@Api/user/cart";
import { CartDto } from "@Types/cart";
import { getModalInstance } from "@Component/common/Modal/ModalProvider";

interface SizeCounterProps {
    product?: ProductDto.Response;
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    cart?: boolean;
}

export const SizeCounter: React.FC<SizeCounterProps> = ({ product, data, setData, cart }) => {
    const modal = getModalInstance();
    const [totalPrice, setTotalPrice] = useState<number>(0);

    useEffect(() => {
        // 초기 데이터가 없으면 반환
        if (!data || data.item.length === 0 && !data.size) {
            return;
        }

        const selectSize: string[] = data.size.split(',');
        let tempItemList: OrderDto.Item[] = data.item.filter((item: OrderDto.Item) => selectSize.includes(item.size!));

        // 각 사이즈에 대해 새 아이템 추가
        selectSize.forEach((size) => {
            if (size === '') return;
            const existsInData = tempItemList.some((item: OrderDto.Item) => item.size === size);

            if (!existsInData) {
                const newItem: OrderDto.Item = {
                    product: product,
                    size: size,
                    count: 1
                };
                tempItemList.push(newItem);
            }
        });

        // 데이터 업데이트
        const updatedData = {
            ...data,
            item: tempItemList,
            size: tempItemList.map((item) => item.size || '').join(','),
        };
        setData(updatedData);
        // Update total price after updating data
        updateTotalPrice(tempItemList);

    }, [data.size, product]); // product 추가

    useEffect(() => {
        // data.item이 업데이트 될 때마다 totalPrice 계산
        updateTotalPrice(data.item);
    }, [data.item]); // data.item에 따라 totalPrice 업데이트

    const updateTotalPrice = (itemList: OrderDto.Item[]) => {
        const newTotalPrice = itemList.reduce((sum, item) => {
            const itemPrice = Number(product?.price) * (item.count ?? 1);
            return sum + itemPrice;
        }, 0);
        setTotalPrice(newTotalPrice);
    };

    const changeItemCount = async (size: string, count: number) => {
        if (count <= 0 || isNaN(count)) {
            return;
        }

        const tempItemList = data.item.map((item: OrderDto.Item) =>
            item.size === size ? {...item, count} : item
        );

        if (cart && tempItemList.length !== 0) {
            const tempCart: OrderDto.Item[] = [{product: product, size: size, count: count}];
            await insertCartApi(tempCart, true);
        }

        const updatedData = {...data, item: tempItemList};
        setData(updatedData); // 상태 업데이트

        // 총 가격 업데이트
        updateTotalPrice(tempItemList);

    };

    const addItemCount = async (size: string) => {
        const tempItemList = data.item.map((item: OrderDto.Item) =>
            item.size === size ? {...item, count: (item.count ?? 0) + 1} : item
        );

        if (cart && tempItemList.length !== 0) {
            const tempCart = data.item
                .filter((item: OrderDto.Item) => item.size === size)
                .map((item: OrderDto.Item) => ({
                    ...item,
                    count: (item.count ?? 0) + 1
                }));

            await insertCartApi(tempCart, true);
        }

        const updatedData = {...data, item: tempItemList};
        setData(updatedData); // 상태 업데이트

        // 총 가격 업데이트
        updateTotalPrice(tempItemList);


    };

    const subItemCount = async (size: string) => {
        const beforeItem = data.item.find((item: OrderDto.Item) => item.size === size);

        if (beforeItem && beforeItem.count! > 1) {
            const tempItemList = data.item.map((item: OrderDto.Item) =>
                item.size === size ? {...item, count: item.count! - 1} : item
            );

            if (cart && tempItemList.length !== 0) {
                const tempCart = data.item
                    .filter((item: OrderDto.Item) => item.size === size)
                    .map((item: OrderDto.Item) => ({
                        ...item,
                        count: (item.count ?? 0) - 1
                    }));

                await insertCartApi(tempCart, true);
            }

            const updatedData = {...data, item: tempItemList};
            setData(updatedData); // 상태 업데이트

            // 총 가격 업데이트
            updateTotalPrice(tempItemList);


        } else {
            if (cart) {
                modal?.confirm(() => {
                    subSize(size);
                }, '확인', '헤딩옵션을 삭제하시겠습니까?');
            } else {
                subSize(size);
            }
        }
    };

    const subSize = (size: string) => {
        const tempItemList = data.item.filter((item: OrderDto.Item) => item.size !== size);

        if (cart) {
            const tempDeleteDto: CartDto.Delete[] = [{ productId: product?.productId, size }];
            deleteCartApi(tempDeleteDto);
        }

        // Update the `data.size` field by removing the size that was subtracted
        const updatedSize = tempItemList.map((item: OrderDto.Item) => item.size || '').join(',');

        const updatedData = {
            ...data,
            item: tempItemList,
            size: updatedSize // Update size field
        };
        setData(updatedData); // 상태 업데이트

        // 총 가격 업데이트
        updateTotalPrice(tempItemList);
    };

    return (
        <div className={styles.sizeCountPlace}>
            {data.item.map((item: OrderDto.Item) => (
                <div className={`${styles.item} ${cart ? styles.cartItem : ''}`} key={item.size}>
                    <div className={styles.name}>{item.size?.toUpperCase()}</div>
                    <div className={styles.count}>
                        <div>
                            <button onClick={() => subItemCount(item.size!)}>
                                <i className="icon-minus"></i>
                            </button>
                            <input
                                type="number"
                                value={item.count}
                                onChange={(e) => changeItemCount(item.size!, parseInt(e.target.value))}
                            />
                            <button onClick={() => addItemCount(item.size!)}>
                                <i className="icon-plus"></i>
                            </button>
                        </div>
                        <span id={`item-price-${item.size}`}>
                            {(Number(product?.price) * item.count!).toLocaleString()} 원
                        </span>
                        <button onClick={() => {
                            if (cart) {
                                modal?.confirm(() => {
                                    subSize(item.size!)
                                }, '확인', '해당옵션을 삭제시겠습니까?')
                            } else {
                                subSize(item.size!)
                            }
                        }}>
                            <i className="icon-trash"></i>
                        </button>
                    </div>
                </div>
            ))}
            <div className={`${styles.totalPrice} ${cart ? styles.cartTotalPrice : ''}`}>
                총 {totalPrice.toLocaleString()} 원
            </div>
        </div>
    );
};
