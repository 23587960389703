import ReviewList from "@Component/domains/board/List/ReviewList";
import InquiryList from "@Component/domains/board/List/InquiryList";
import DashboardBuilder from "@Component/layout/GridDashBoard/DashboardBuilder";


function AdminOrderModal(props: { orderState: string }) {
    return null;
}

export const AdminDashBoardPage = () => {

    const items = [
        { id: 1, x: 4, y: 1, component: <>각종 집계</> },
        { id: 4, x: 1, y: 5, component: <AdminOrderModal orderState={'주문완료'} /> },
        { id: 5, x: 1, y: 5, component: <AdminOrderModal orderState={'준비중'} /> },
        { id: 6, x: 1, y: 5, component: <AdminOrderModal orderState={'배송중'} /> },
        { id: 7, x: 1, y: 5, component: <AdminOrderModal orderState={'배송완료'} /> },
        { id: 8, x: 1, y: 5, component: <AdminOrderModal orderState={'반품요청'} /> },
        { id: 9, x: 1, y: 5, component: <AdminOrderModal orderState={'교환요청'} /> },
        { id: 10, x: 1, y: 5, component: <AdminOrderModal orderState={'취소요청'} /> },
        { id: 11, x: 1, y: 5, component: <AdminOrderModal orderState={'반품중'} /> },
        { id: 12, x: 1, y: 5, component: <AdminOrderModal orderState={'교환중'} /> },
        { id: 13, x: 1, y: 5, component: <AdminOrderModal orderState={'반품완료'} /> },
        { id: 14, x: 1, y: 5, component: <AdminOrderModal orderState={'교환완료'} /> },
        { id: 15, x: 1, y: 5, component: <AdminOrderModal orderState={'취소완료'} /> },
        {
            id: 2, x: 2, y: 5, component: <>
                <h3>상품 리뷰</h3>
                <ReviewList isAdmin={true} />
            </>
        },
        {
            id: 3, x: 2, y: 5, component: <>
                <h3>상품 문의</h3>
                <InquiryList isAdmin={true} /></>
        },
    ]
    return <DashboardBuilder items={items} />
}
