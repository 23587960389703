import { getModalInstance } from "@Component/common/Modal/ModalProvider";

export interface ValidationOption {
    name: string;
    baseMessage: string;
    nullAble?: boolean;
    nullAbleMessage?: string;
    regex?: RegExp;
    regexMessage?: string;
    duplication?: (value: any) => Promise<boolean>;
    disableSuccessMessage?: boolean;
    customValid?: (data: any) => boolean;  // 추가된 사용자 정의 유효성 검사 함수
    customValidMessage?: string;  // 추가된 사용자 정의 유효성 검사 메시지
    customValidTarget?:string;
    successMessage?:string
}
export const isValid = async (dto: any, options: ValidationOption[], alert: boolean, target?: string) => {
    const modal = getModalInstance();

    for (const option of options) {
        const {
            name,
            baseMessage,
            nullAble,
            regex,
            regexMessage,
            duplication,
            disableSuccessMessage,
            nullAbleMessage,
            customValid,
            customValidMessage,
            customValidTarget,
            successMessage
        } = option;

        if (target && target !== name) {
            continue;
        }

        const value = dto[name];
        const messageEl = document.getElementById('form-message-' + (customValidTarget || name));
        const inputEl = document.getElementById('form-input-' + name);
        const base = baseMessage?.slice(0, -1); // 마지막 문자 제거
        // Null Check
        if (!nullAble && !value) {
            const message = nullAbleMessage || (baseMessage + ' 입력해주세요');
            if (alert) {
                modal?.alert(() => {
                    setTimeout(() => {
                        inputEl?.focus();
                    }, 100);
                }, '알림', message);
            }
            if (messageEl) {
                messageEl.innerText = message;
                messageEl.style.color = 'red';
            }
            return false; // 검사 실패 시 즉시 false 반환
        }

        // Regex Check
        if (regex && !regex.test(value)) {
            const message = regexMessage || `잘못된 형식의 ${base}입니다.`;
            if (alert) {
                modal?.alert(() => {
                    setTimeout(() => {
                        inputEl?.focus();
                    }, 100);
                }, '알림', message);
            }
            if (messageEl) {
                messageEl.innerText = message;
                messageEl.style.color = 'red';
            }
            return false; // 검사 실패 시 즉시 false 반환
        }

        // Duplication Check
        if (duplication && !(await duplication(dto))) {
            const message = `중복된 ${base}입니다.`;
            if (alert) {
                modal?.alert(() => {
                    setTimeout(() => {
                        inputEl?.focus();
                    }, 100);
                }, '알림', `${baseMessage} 확인해주세요`, message);
            }
            if (messageEl) {
                messageEl.innerText = message;
                messageEl.style.color = 'red';
            }
            return false; // 검사 실패 시 즉시 false 반환
        }

        // Custom Validation
        if (customValid && !customValid(dto)) {
            const message = customValidMessage || `유효하지 않은 ${base}입니다.`;
            if (alert) {
                modal?.alert(() => {
                    setTimeout(() => {
                        inputEl?.focus();
                    }, 100);
                }, '알림', message);
            }
            if (messageEl) {
                messageEl.innerText = message;
                messageEl.style.color = 'red';
            }
            return false; // 검사 실패 시 즉시 false 반환
        }

        // Success Message
        if (messageEl) {
            messageEl.innerText = disableSuccessMessage ? '' : (successMessage || `사용가능한 ${base}입니다.`);
            messageEl.style.color = 'blue';
        }
    }

    return true; // 모든 검사가 통과한 경우 true 반환
};

export const setNumber = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const target = event.target;
    let value = target.value;

    // 숫자가 아닌 문자를 제거
    value = value.replace(/[^0-9]/g, '');

    // 숫자 형식으로 포맷팅
    const numberValue = parseFloat(value);

    // 숫자가 NaN인지 확인
    const formattedValue = isNaN(numberValue) ? '' : numberValue.toLocaleString();

    // 포맷팅된 값을 HTMLSelectElement에 다시 설정
    target.value = formattedValue;

    // 실제로 데이터에 숫자만 저장하거나 다른 처리를 하기 위해 setState 등을 사용할 수 있음
};


export const setPhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    let value = target.value;

    // 숫자가 아닌 문자를 제거
    value = value.replace(/[^0-9]/g, '');

    // 포맷팅된 전화번호 설정 (예: 010-1234-5678)
    let formattedValue = '';

    if (value.length > 0) {
        formattedValue += value.slice(0, 3); // 첫 3자리
    }
    if (value.length >= 4) {
        formattedValue += '-' + value.slice(3, 7); // 다음 4자리
    }
    if (value.length >= 8) {
        formattedValue += '-' + value.slice(7, 11); // 마지막 4자리
    }

    // 포맷팅된 값을 HTMLInputElement에 다시 설정
    target.value = formattedValue;
};

export const setEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value;

    // 이메일 형식 검증 (간단한 정규 표현식 사용)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 유효한 이메일인지 확인
    if (!emailRegex.test(value)) {
        // 유효하지 않은 이메일 처리 (예: 경고 메시지 표시 등)
        // 필요에 따라 경고 메시지를 표시하는 로직을 추가할 수 있습니다.
        console.warn('유효하지 않은 이메일 형식입니다.');
    }

    // 이메일이 유효하면 그대로 유지 (값 변경 없음)
    // target.value는 변경하지 않음
};
