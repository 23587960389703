import {LinkItem} from "@Component/common/LinkBuilder";

export const mainLink: LinkItem[] = [
    { path: '/product/list', label: '상품' },
    { path: '/board/notice', label: '커뮤니티' },
    { path: '/shopinfo', label: '정보' }
];

export const adminMainLink: LinkItem[] = [
    { path: '/admin/product/list', label: '상품' },
    { path: '/admin/order?orderType=주문', label: '주문' },
    { path: '/admin/board/list/notice', label: '게시판' }
];
export const boardLinks: LinkItem[] = [
    { path: '/board/notice', label: '공지사항' },
    { path: '/board/inquiry', label: '상품문의' },
    { path: '/board/review', label: '상품리뷰' }
];

export const shopLinks: LinkItem[] = [
    { path: '/shopinfo', label: '회사소개' },
    { path: '/privacy', label: '개인정보 처리방침' },
    { path: '/agreement', label: '이용약관' },
];

export const adminLinks: LinkItem[] = [
    { path: '/admin/product/list/비공개', label: '비공개 상품', id: 'primary' },
    { path: '/admin/product', label: '상품 등록' },
    { path: '/admin/product/category', label: '카테고리 수정' },
    { path: '/admin/make-3d', label: '3D 이미지 제작' },
    { path: '/admin/main-image', label: '메인 이미지 수정' },
    { path: '/admin/order/insert', label: '주문내역등록' }
];

export const orderLinks: LinkItem[] = [
    { path: '/admin/order?orderType=주문', label: '주문 내역' },
    { path: '/admin/order?orderType=교환', label: '교환 접수' },
    { path: '/admin/order?orderType=반품', label: '반품 접수' },
    { path: '/admin/order?orderType=취소', label: '취소 접수' }
];

export const adminBoardLinks: LinkItem[] = [
    { path: '/admin/board/list/notice', label: '공지사항' },
    { path: '/admin/board/list/inquiry', label: '상품문의' },
    { path: '/admin/board/list/review', label: '상품리뷰' },
    { path: '/admin/board/list/unprocessed', label: '미답변' }
];