import React, { useEffect, useState } from 'react'
import Item from '@Component/layout/ListField/Item'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import ListTemplate, {
    RowOption,
} from '@Component/layout/ListField/ListTemplate'
import Header from '@Component/layout/ListField/Header'
import Footer, { FooterOption } from '@Component/layout/ListField/Footer'
import { PageBar } from '@Component/common/PageBar'
import { BoardCategory, BoardDto, createBoardSearchDto } from '@Types/board'
import { useNavigate } from 'react-router-dom'
import { getUserInfo } from '@Api/user/account'
import { ReviewEditor } from '@Component/domains/board/Edit/ReviewEditor'
import { getBoardListApi } from '@Api/user/board'
import { ReviewView } from '@Component/domains/board/View/ReviewView'
import SearchField, { ActionField } from '@Component/layout/SearchField'
import { boardSearch } from './searchFeild'
import {NoticeEditor} from "@Component/domains/board/Edit/NoticeEditor";
import {formatDate} from "@Util/dateUtile";
import {NoticeView} from "@Component/domains/board/View/NoticeView";
import {getAdminBoardListApi} from "@Api/admin/board";

interface Props {
    productId?: string
    isAdmin?: boolean
}

const NoticeList: React.FC<Props> = ({ productId, isAdmin }) => {
    const navigate = useNavigate()
    const modal = getModalInstance()
    const [search, setSearch] = useState<BoardDto.Search>(createBoardSearchDto(BoardCategory.Notice))
    const [boardList, setBoardList] = useState<BoardDto.Response[]>([])
    const [page, setPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [rowSize, setRowSize] = useState<number>(10)

    const headerOptions = [
        { name: '번호', rowCount: 1, width: '20%' },
        { name: '제목', rowCount: 1, width: '65%' },
        { name: '작성일', rowCount: 1, width: '15%' },
    ]

    const rowOptions: RowOption[] =
        boardList.length !== 0
            ? boardList.map((board, index) => ({
                  rowList: [
                      <Item
                          key={`no-${index}`}
                          content={totalCount - (page - 1) * rowSize - index}
                          colSpan={1}
                      />,
                      <Item
                          key={`title-${index}`}
                          content={board?.title}
                          colSpan={1}
                      />,
                      <Item key={`date-${index}`}  content={formatDate(board.saveDate, 'yyyy/MM/dd')} colSpan={1} />,
                  ],
                  onClick: () => {
                      modal?.popup(
                          isAdmin?<NoticeEditor boardId={board.boardId} /> : <NoticeView boardId={board.boardId}/>,
                          '800px',
                          () => {
                              fetchData()
                          }
                      )
                  },
              }))
            : [
                  {
                      rowList: [
                          <Item
                              key="no-reviews"
                              content={
                                  <div className={'empty-view'}>
                                      공지가 존재하지 않습니다.
                                  </div>
                              }
                              colSpan={4}
                          />,
                      ],
                  },
              ]

    const footerOptions: FooterOption[] = [
        ...(isAdmin
            ? [
                  {
                      name: '작성하기',
                      onClick: () => {
                          handleWrite()
                      },
                  },
              ]
            : []),
    ]

    const actionFieldList: ActionField[] = [
        {
            label: '검색',
            onClick: () => {  fetchData()},
        },
    ]

    const handleWrite = async () => {
        if (await getUserInfo(true)) {
            modal?.popup(
                <NoticeEditor/>,
                '800px',
                () => {
                    fetchData()
                }
            )
        } else {
            modal?.confirm(
                () => {
                    navigate('/user/login')
                },
                '알림',
                '작성을 위해 로그인이 필요합니다.',
                '로그인 하시겠습니까?'
            )
        }
    }

    const fetchData = async () => {
        const temp = search
        temp.page = page
        temp.rowSize = rowSize

        const result = isAdmin ?await getAdminBoardListApi(temp) : await getBoardListApi(temp)
        setBoardList(result?.content || [])
        setTotalCount(result?.totalElements || 0)
    }

    useEffect(() => {
        fetchData()
    }, [productId, page])

    return (
        <>
            {isAdmin && (
                <SearchField
                    searchFieldList={boardSearch(BoardCategory.Notice)}
                    actionFieldList={actionFieldList}
                    data={search}
                    setData={setSearch}
                />
            )}
            <ListTemplate
                header={<Header options={headerOptions} />}
                row={rowOptions}
                footer={<Footer options={footerOptions} />}
            />
            <PageBar
                rowSize={rowSize}
                totalCount={totalCount}
                page={page}
                setPage={setPage}
            />
        </>
    )
}

export default NoticeList
