import { useLocation } from 'react-router-dom'
import { MypageOrders } from '@Component/domains/user/MypageOrders'
import MypageBoard from '@Component/domains/user/MypageBoard'
import { TabBar } from '@Component/layout/TabBar'
import MainFrame from '@Component/layout/MainFrame'
import AccountInfo from '@Component/domains/user/AccountInfo'

export const MyPage = () => {
    const location = useLocation()
    const path = location.pathname

    const myPageTabOptions = [
        { label: '계정관리', link: '/mypage/account' },
        { label: '주문확인', link: '/mypage/orders' },
        { label: '활동 및 문의', link: '/mypage/activity' },
    ]

    return (
        <MainFrame className="mypage" maxWidth={1000}>
            <TabBar options={myPageTabOptions} />
            {path == '/mypage/account' && <AccountInfo />}
            {path == '/mypage/orders' && <MypageOrders />}
            {path == '/mypage/activity' && <MypageBoard />}
        </MainFrame>
    )
}
