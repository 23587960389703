// HTTP 요청을 위한 함수
import {HttpAlert, HttpSuccess} from "@Types/cmm/HttpCallBack";
import {getRequest} from "@Api/Api";
import {DeliveryState} from "@Types/order/orderDto";

export const getTracking = async (
    carrierId: string,
    trackingNumber: string
) => {
    const httpSuccess: HttpSuccess = {}
    const httpAlert: HttpAlert = {}
    try {
        return await getRequest<DeliveryState[]>(
            `/tracking?carrierId=${carrierId}&trackingNumber=${trackingNumber}`,
            httpSuccess,
            httpAlert,
            {}
        )
    } catch (e) {
        console.log(e)
        return false
    }
}
