import React, {useEffect, useState} from 'react'
import {convertToJpg, isJpeg} from '@Util/imageUtils'
import {postRequest} from '@Api/Api'

import * as process from 'process'
import {getModalInstance} from '@Component/common/Modal/ModalProvider'
import './Editor.css'
import {decodeHTML} from "@Util/stringUtils";

export const Editor: React.FC<{
    data: any
    setData:  React.Dispatch<React.SetStateAction<any>>
    name: string
    height?: number
    userSet?: boolean
    defaultData?: string
}> = ({data,setData,name, height = 600, userSet = false,defaultData}) => {
    const modal = getModalInstance()
    const [content,setContent ] = useState<string>()
    async function uploadSummernoteImageFile(files: FileList) {
        const data = new FormData()
        for (let i = files.length - 1; i >= 0; i--) {
            const file = files[i]

            if (isJpeg(file)) {
                data.append('file', file)
            } else {
                data.append('file', await convertToJpg(file))
            }

            const httpSuccess = {}
            const httpAlert = {
                401: () => {
                    modal?.alert(
                        () => {
                        },
                        '알림',
                        '게시글 작성은 로그인 후 가능합니다.'
                    )
                },
            }
            try {
                const src = await postRequest<string>(
                    '/uploadSummernoteImageFile',
                    data,
                    httpSuccess,
                    httpAlert,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                )
                const fileName = file.name

                const index = fileName.lastIndexOf('.')
                const fileNameWithoutExtension =
                    index !== -1 ? fileName.substring(0, index) : fileName


                // Create img element with jQuery
                const $img = $('<img>')
                    .attr('src', process.env.BASE_URL + src)
                    .attr('alt', fileNameWithoutExtension)
                    .css('width', '100%') // Set width to 100%

                // Append img to .note-editable element
                $('.note-editable').append($img)
            } catch (e) {
                console.log(e)
                break
            }
        }
    }
    useEffect(() => {
        // summernote옵션 설정
        const config: Summernote.Options = {
            styleWithSpan: false,
            height: height, // 에디터 높이
            lang: 'ko-KR',

            toolbar: userSet
                ? [
                    ['style', ['style']],
                    ['fontsize', ['fontsize']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['height', ['height']],
                ]
                : [
                    ['style', ['style']],
                    ['fontsize', ['fontsize']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['height', ['height']],
                    ['table', ['table']],
                    ['para', ['paragraph']],
                    ['insert', ['picture', 'link', 'hr']],
                    ['view', ['codeview', 'fullscreen', 'help']],
                ],

            fontSizes: [
                '8',
                '9',
                '10',
                '11',
                '12',
                '14',
                '16',
                '18',
                '20',
                '22',
                '24',
                '28',
                '30',
                '36',
                '50',
                '72',
            ],
            styleTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
            callbacks: {
                onInit: () => {
                    $('.note-editable').css('font-size', 'var(--font-size-1)') // 기본 글씨 크기 설정
                    $('.note-editable').css('line-height', '1.8') // 행간 설정
                    $('.note-toolbar .note-btn').css(
                        'font-size',
                        'var(--font-size-0-9)'
                    )
                },
                onChange: (contents: string, $editable: JQuery) => {
                    setContent(contents);
                },

                onImageUpload: (files: FileList) => {
                    // 일반유저는 edit에 이미지 업로드 불가
                    if (userSet) {
                        return false
                    }
                    // 파일 업로드(다중 업로드를 위해 반복문 사용)
                    uploadSummernoteImageFile(files).then(() => {
                        const updatedContents = $('#summernote').summernote('code');
                        setContent(updatedContents);
                    });
                },
                onKeyup: (ev: KeyboardEvent) => {
                },
                onKeydown: (ev: KeyboardEvent) => {
                },
                onPaste: (e: Event & { originalEvent: ClipboardEvent }) => {
                },
            },
        }

        $('#summernote').summernote(config)
    }, [height, userSet]);
    useEffect(() => {
        const temp = data ;
        temp[name] = content;
        setData(temp);
    }, [content]);
    useEffect(() => {
        if (data[name]) {
            $('#summernote').summernote(
                'code',
                data[name]
                    ? decodeHTML(data[name].replaceAll(
                          '/temp/',
                        process.env.TEMP_IMAGE_BASE_URL!

                    ))
                    : ''
            )
        }
    }, [defaultData]);


    return (
        <>
            <div style={{width: '100%'}}>
                <textarea id="summernote"></textarea>
            </div>
        </>
    )
}
