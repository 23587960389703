import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import { useEffect, useState } from 'react'
import { searchAddressService } from '@Api/cmm/CmmService'
import { AddressDto } from '@Types/address'
import { PageBar } from '@Component/common/PageBar'
import './SearchAddress.css'
import Button from '@Component/common/Input/Button'

interface Props {
    data: any
    setData: React.Dispatch<React.SetStateAction<any>>
    name: string
}

const SearchAddress: React.FC<Props> = ({ data, setData, name }) => {
    const modal = getModalInstance()
    const [addressList, setAddressList] = useState<any[]>([])
    const [page, setPage] = useState<number>(1)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [rowSize, setRowSize] = useState<number>(10)

    const searchAddress = async (schPage?: number) => {
        const keyword = (
            document.getElementById('address-keyword') as HTMLInputElement
        ).value
        if (keyword == '') {
            modal?.alert(() => {}, '알림', '검색어를 입력해주세요')
            return
        }
        const search: AddressDto.Search = {
            page: schPage,
            keyword: (
                document.getElementById('address-keyword') as HTMLInputElement
            ).value,
        }
        const response: any = await searchAddressService(search)
        if (response?.addressList?.length == 0) {
            modal?.alert(
                () => {},
                '알림',
                '검색결과가 없습니다.',
                '검색값을 확인해주세요.'
            )
            return
        }
        setAddressList(response?.addressList || [])
        setTotalCount(response?.totalCount || 0)
    }

    useEffect(() => {
        if (addressList.length != 0) {
            searchAddress(page)
        }
    }, [page])

    return (
        <div className={'address-search-view'}>
            <h3>주소 검색</h3>
            <div className={'search-area'}>
                <input
                    id={'address-keyword'}
                    type={'text'}
                    placeholder={'ADDRESS KEYWORD'}
                />
                <i
                    className={'icon-search'}
                    onClick={() => {
                        searchAddress(1)
                    }}
                ></i>
            </div>
            <table className={'info-table'}>
                <colgroup>
                    <col width="*" />
                    <col width="12%" />
                </colgroup>
                <thead>
                    <th className={'center'}>도로명 주소</th>
                    <th className={'center'}>선택</th>
                </thead>
                <tbody>
                    {addressList.length != 0 ? (
                        addressList?.map((address, index) => (
                            <tr key={index}>
                                <td>
                                    [{address?.zipNo}] {address?.roadAddr}
                                    <br />
                                    <p>{address?.jibunAddr}</p>
                                </td>
                                <td className={'center'}>
                                    <Button
                                        name={'선택'}
                                        onClick={() => {
                                            const updatedData = {
                                                ...data,
                                                [name]: `[${address?.zipNo}] ${address?.roadAddr}`,
                                            }

                                            setData(updatedData)
                                            modal?.closeModal()
                                        }}
                                    />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                className="open-left open-right center"
                                colSpan={3}
                                height={150}
                            >
                                주소를 검색해주세요.
                            </td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td
                            className={'center'}
                            colSpan={3}
                            style={{ border: 'none' }}
                        >
                            <PageBar
                                rowSize={10}
                                page={page}
                                totalCount={totalCount}
                                setPage={setPage}
                            />
                        </td>

                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default SearchAddress
