import React, { useState } from 'react'
import { getModalInstance } from '@Component/common/Modal/ModalProvider'
import MainFrame from '@Component/layout/MainFrame'
import styles from '@Css/form.module.scss'
import FormField from '@Component/layout/FormField'
import {
    saveValid,
    saveValid2,
} from '@Page/admin/order/AdminOrderInsertPage/valid'
import Button from '@Component/common/Input/Button'
import {
    formList,
    formList2,
    formList3,
} from '@Page/admin/order/AdminOrderInsertPage/form'
import { createOrderInsertDto, OrderDto } from '@Types/order'
import { isValid } from '@Util/formUtils'
import { insertOrderApi } from '@Api/admin/order'
import { useNavigate } from 'react-router-dom'

const AdminOrderInsertPage = () => {
    const navigate = useNavigate()
    const [saveDto, setSaveDto] =
        useState<OrderDto.AdminInsert>(createOrderInsertDto)
    const modal = getModalInstance()

    return (
        <MainFrame
            className={styles.form}
            maxWidth={900}
            title={`주문 등록`}
            documentTitle={`주문 등록`}
        >
            <FormField
                formFieldList={[
                    ...formList,
                    ...(saveDto?.paymentType === 'ACCOUNT' ? formList2 : []), // 조건에 따라 formList2 추가
                    ...formList3,
                ]}
                data={saveDto}
                setData={setSaveDto}
                validOption={[
                    ...saveValid,
                    ...(saveDto?.paymentType === 'ACCOUNT' ? saveValid2 : []), // 조건에 따라 formList2 추가
                ]}
            />
            <div>
                <Button
                    extraClass={styles.halfButton}
                    name={'내역 저장'}
                    onClick={async () => {
                        if (
                            (await isValid(saveDto, saveValid, true)) &&
                            (saveDto?.paymentType !== 'ACCOUNT' ||
                                (await isValid(saveDto, saveValid2, true)))
                        ) {
                            if (await insertOrderApi(saveDto)) {
                                modal?.alert(
                                    () => {
                                        navigate('/admin/order?orderType=주문')
                                    },
                                    '알림',
                                    '주문 내역이 저장되었습니다.'
                                )
                            }
                        }
                    }}
                />
            </div>
        </MainFrame>
    )
}

export default AdminOrderInsertPage
