import React, {useEffect, useRef, useState} from 'react'
import {onErrorImg} from '@Util/imageUtils'
import './index.module.scss'
import {disableScroll, enableScroll} from '@Util/viewUtils'
import ImageView from "@Component/common/Image/ImageView/ImageView";
import styles from './index.module.scss'

const ImageViewList = (props: {
    parentClass: string
    imageUrl: string[]
}) => {
    const [offsetX, setOffsetX] = useState(0)
    const imageListRef = useRef<HTMLDivElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const startX = useRef(0)
    const initialOffset = useRef(0)
    const imageUrl = props.imageUrl

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        const touchStartX = e.touches[0].clientX
        const initialOffsetX = offsetX

        const handleTouchMove = (moveEvent: TouchEvent) => {
            const touchMoveX = moveEvent.touches[0].clientX
            const deltaX = touchStartX - touchMoveX
            updateOffsetX(initialOffsetX - deltaX)
        }

        const handleTouchEnd = () => {
            document.removeEventListener('touchmove', handleTouchMove)
            document.removeEventListener('touchend', handleTouchEnd)
        }

        document.addEventListener('touchmove', handleTouchMove, {
            passive: false,
        })
        document.addEventListener('touchend', handleTouchEnd)

        disableScroll()
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        startX.current = e.pageX
        initialOffset.current = offsetX

        const handleMouseMove = (e: MouseEvent) => {
            e.preventDefault()
            const x = e.pageX
            const newOffset = initialOffset.current + (x - startX.current)
            updateOffsetX(newOffset)
        }

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }

        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const updateOffsetX = (newOffset: number) => {
        if (!imageListRef.current || !wrapperRef.current) return

        const imageCount = imageUrl.length
        newOffset = Math.min(0, newOffset)
        const maxOffset =
            wrapperRef.current.offsetWidth -
            (imageCount * 80 + (imageCount - 1) * 10)
        newOffset = Math.max(newOffset, maxOffset)

        setOffsetX(newOffset)

        // 각 이미지 요소에 transform 적용
        Array.from(imageListRef.current.children).forEach((child) => {
            ;(
                child as HTMLElement
            ).style.transform = `translateX(${newOffset}px)`
        })
    }
    const handleResize = () => {
        updateOffsetX(offsetX)
    }

    const openFiexdImage = () => {
        ;(
            document.querySelector('.' + styles.fiexdImage) as HTMLElement
        ).style.display = 'block'
        ;(
            document.querySelector('.modal-close-button') as HTMLElement
        ).style.display = 'none'
    }

    const closeFiexdImage = () => {
        ;(
            document.querySelector('.' + styles.fiexdImage) as HTMLElement
        ).style.display = 'none'
        ;(
            document.querySelector('.modal-close-button') as HTMLElement
        ).style.display = 'block'
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [offsetX])

    return (
        <div className={props.parentClass + ' ' + styles.imageView}>
            <div className={styles.fiexdImage}>
                <div className={styles.view}>
                    <div
                        className={styles.closeButton}
                        onClick={closeFiexdImage}
                    >
                        <i className="icon-cancel"></i>
                    </div>
                    <ImageView imageUrl={imageUrl}/>
                </div>
            </div>
            <div className={styles.imageSelect}>
                <div
                    className={styles.wrapper}
                    ref={wrapperRef}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={enableScroll}
                    style={{overflow: 'hidden'}}
                >
                    <div
                        className={styles.list}
                        ref={imageListRef}
                        style={{whiteSpace: 'nowrap'}}
                    >
                        {props.imageUrl.map((url, index) => (
                            <div
                                className={'image-' + index}
                                key={index}
                                style={{
                                    transform: `translateX(${offsetX}px)`,
                                }}
                            >
                                <img
                                    src={process.env.BASE_URL + url}
                                    onError={(e) => onErrorImg(e)}
                                    draggable="false"
                                    onClick={() => openFiexdImage()}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageViewList