import './OrderModal.css'

export const OrderModal = () => {
//     const [orderState, setOrderState] = useState<string>('')
//     const [orderName, setOrderName] = useState<string>('')
//
//     const [page, setPage] = useState<number>(1)
//     const [orderList, setOrderList] = useState<Order[]>()
//     const [searchParams] = useSearchParams()
//     const orderType = searchParams.get('orderType')
//     const [totalCount, setTotalCount] = useState<number>(1)
//
//     const [thumbnailSrc, sethumbnailSrc] = useState<string[]>([])
//
//     const fetchData = async () => {
//         const search: OrderSearchDTO = {
//             page: page,
//             startDate: formatDateToString(subMonths(new Date(), 3)),
//             endDate: formatDateToString(new Date()),
//             paymentType: '',
//             orderState: orderState,
//             orderCode: '',
//             orderName: orderName,
//             deliveryCode: '',
//             admin: false,
//         }
//         const result = await getOrderListService(search)
//         setOrderList(result?.orderList || [])
//         setTotalCount(result?.count || 1)
//         sethumbnailSrc(result?.orderThumbnailSrc || [])
//     }
//
//     useEffect(() => {
//         fetchData()
//     }, [orderType])
//
//     useEffect(() => {
//         fetchData()
//     }, [page])
//     return (
//         <div className={'order-modal'}>
//             <h2>주문목록 <a className={'bg-black-button'} href={'/user/mypage/orders'}>전체보기</a> </h2>
//             <div>
//                 {orderList?.map((order, index) => (
//                     <OrderFlexItem
//                         order={order}
//                         key={index}
//                         thumbnailSrc={thumbnailSrc[index]}
//                     />
//                 ))}
//                 {orderList?.length == 0 && (
//                     <div className="empty-view"> 해당하는 주문이 없습니다.</div>
//                 )}
//             </div>
//             <OrderPageBar
//                 page={page}
//                 totalCount={totalCount}
//                 perPage={6}
//                 setPage={setPage}
//             />
//         </div>
//     )
// }
//
// export const OrderFlexItem = (props: {
//     order: Order
//     thumbnailSrc: string
// }) => {
//     const order = props.order
//     const modal = getModalInstance()
//     return (
//         <div className="order-history-item">
//             <div className="order-history-item-header">
//                 <p>{order.orderState}</p>
//                 <p>
//                     <span> {order.orderCode}</span>
//                     <input
//                         className="bg-white-button"
//                         type="button"
//                         value={'상세조회'}
//                         onClick={() => {
//                             modal?.popup(
//                                 <OrderView orderCode={order?.orderCode || ''} />
//                             )
//                         }}
//                     />
//                 </p>
//             </div>
//             <div className="order-history-item-body">
//                 <div className="order-history-item-thumbnail">
//                     <img
//                         src={`${process.env.BASE_URL}${props.thumbnailSrc}/THUMBNAIL/0.jpg`}
//                         alt={`${order.orderName} thumbnail`}
//                         onError={(e) => onErrorImg(e)}
//                     />
//                 </div>
//                 <div className="order-history-item-info">
//                     <p>{order.orderName}</p>
//                     {order.deliveryCode && (
//                         <a
//                             onClick={() => {
//                                 modal?.popup(
//                                     <DeliveryTracker
//                                         deliveryCode={order.deliveryCode!}
//                                     />
//                                 )
//                             }}
//                         >
//                             배송번호 : {`${carrierNameMapping[order.deliveryCode.split('///')[0]]} ${order.deliveryCode.split('///')[1]}`}
//                         </a>
//                     )}
//
//                     <div>
//                         <p>
//                             {` 결제금액 : ${parseInt(
//                                 order.paymentAmount || ''
//                             ).toLocaleString()} 원`}
//                         </p>
//                         <p>
//                             <span>
//                                 {formatDateToString(
//                                     order.orderDate
//                                         ? new Date(order.orderDate)
//                                         : null
//                                 )}
//                             </span>
//                         </p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
//
//
//
// export const AdminOrderModal = (props:{orderState:string, user?:UserInfo}) => {
//     const modal = getModalInstance();
//     const [orderState, setOrderState] = useState<string>('')
//     const [orderCode, setOrderCode] = useState<string>('')
//     const [page, setPage] = useState<number>(1)
//     const [orderList, setOrderList] = useState<Order[]>()
//     const [totalCount, setTotalCount] = useState<number>(1)
//    const fetchData = async () => {
//
//         const search: OrderSearchDTO = {
//             paymentType: "",
//             page: page,
//             startDate: formatDateToString(subMonths(new Date(), 3)),
//             endDate: formatDateToString(new Date()),
//             orderState: props.orderState,
//             orderCode: orderCode,
//             orderName: '',
//             admin: true,
//             deliveryCode:'',
//             userId: props.user?.userId
//         }
//         const result = await getOrderListService(search)
//         setOrderList(result?.orderList || [])
//         setTotalCount(result?.count || 1)
//     }
//
//
//     const exportExcel = async () => {
//
//         const search: OrderSearchDTO = {
//             paymentType: "",
//             page: page,
//             startDate: formatDateToString(subMonths(new Date(), 3)),
//             endDate: formatDateToString(new Date()),
//             orderState: props.orderState,
//             orderCode: orderCode,
//             orderName: '',
//             admin: true,
//             deliveryCode:'',
//             userId: props.user?.userId
//         }
//         const excelDTO = await getOrderExcelListService(search)
//
//         const orderList = excelDTO?.orderList
//
//         if(!orderList || orderList?.length==0){
//             modal?.alert(()=>{},'알림',' 조회 내역이 없습니다.')
//             return
//         }
//
//         let data: any[] = []
//         // orderList.map((order) => {
//         //     data.push(
//         //         [
//         //             'h_주문번호',
//         //             'h_수령인',
//         //             'h_수령인 전화번호',
//         //             'h_배송요청사항',
//         //             'h_주소',
//         //         ],
//         //         [
//         //             order?.order?.orderCode,
//         //             order?.order?.recipientName,
//         //             order?.order?.recipientContact,
//         //             order?.order?.deliveryRequest,
//         //             decodeHTML(order?.order?.address || '').replace('///', ' '),
//         //         ],
//         //         [
//         //             'h_상품명',
//         //             'h_상품코드',
//         //             'h_사이즈',
//         //             'h_개수',
//         //             'h_상품페이지 주소',
//         //         ]
//         //     )
//         //     order.orderItemList?.forEach((orderItem, index) => {
//         //         const temp: string[] = [
//         //             index == 0 ? orderItem.product.name! : '',
//         //             index == 0 ? orderItem.product.productCode! : '',
//         //             orderItem.size,
//         //             orderItem.count,
//         //             index == 0
//         //                 ? 'https://brospo.co.kr/order/product/' +
//         //                 orderItem.product.productId
//         //                 : '',
//         //         ]
//         //         data.push(temp)
//         //     })
//         //     data.push(['_hr_','_hr_','_hr_','_hr_','_hr_'])
//         // })
//         await generateExcel(data, '주문정보', '주문목록.xlsx')
//     }
//
//
//
//
//     useEffect(() => {
//         if (orderState == '') {
//             fetchData();
//         }
//     }, [orderState]);
//
//     useEffect(() => {
//         fetchData()
//     }, [page])
//     return (
//         <div className={'order-modal'}>
//             <h2>{props.user? props.user.userName + '님의 최근주문' : props.orderState}<input className={'bg-white-button'} onClick={exportExcel} type={"button"} value={'엑셀다운'}/></h2>
//             <div>
//                 <table className={'order-table'}>
//                     <colgroup>
//                         <col width="12.5%" />
//                         <col width="12.5%" />
//                         <col width="12.5%" />
//                         <col width="12.5%" />
//                         <col width="12.5%" />
//                         <col width="12.5%" />
//                         <col width="12.5%" />
//                         <col width="12.5%" />
//                     </colgroup>
//                     <thead>
//                         <tr>
//                             <th colSpan={8}>주문명</th>
//                         </tr>
//
//                         <tr>
//                             <th colSpan={2}>주문상태</th>
//                             <th colSpan={2}>결제수단</th>
//                             <th colSpan={2}>주문자명</th>
//                             <th colSpan={2}>주문일자</th>
//                         </tr>
//
//                         <tr>
//                             <th colSpan={3}>주문번호</th>
//                             <th colSpan={3}>배송번호</th>
//                             <th colSpan={2}>주문금액</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {orderList?.map((order, index) => (
//                             <>
//                                 <tr
//                                     onClick={() => {
//                                         modal?.popup(
//                                             <AdminOrderView orderCode={order?.orderCode || ''} />,undefined,undefined,  fetchData
//                                         )
//                                     }}
//                                 >
//                                     <td colSpan={8}>{order.orderName}</td>
//                                 </tr>
//                                 <tr
//                                     onClick={() => {
//                                         modal?.popup(
//                                             <AdminOrderView orderCode={order?.orderCode || ''} />,undefined,undefined,  fetchData
//                                         )
//                                     }}
//                                 >
//                                     <td colSpan={2}>{order.orderState}</td>
//                                     <td colSpan={2}>{order.paymentType}</td>
//                                     <td colSpan={2}>{order.user?.userName}</td>
//                                     <td colSpan={2}>
//                                         {formatDateToString(
//                                             order.orderDate ? new Date(order.orderDate) : null
//                                         )}
//                                     </td>
//                                 </tr>
//                                 <tr
//                                     onClick={() => {
//                                         modal?.popup(
//                                             <AdminOrderView orderCode={order?.orderCode || ''} />,undefined,undefined,  fetchData
//                                         )
//                                     }}
//                                 >
//                                     <td colSpan={3}>{order.orderCode}</td>
//                                     <td colSpan={3}>{order?.deliveryCode ? `${carrierNameMapping[order.deliveryCode.split('///')[0]]} ${order.deliveryCode.split('///')[1]}` : '-'}
//                                     </td>
//                                     <td colSpan={2}>
//                                         {parseInt(order.paymentAmount || '').toLocaleString()} 원
//                                     </td>
//                                 </tr>
//                             </>
//                         ))}
//                         {orderList?.length == 0 && (
//                             <tr>
//                                 <td colSpan={8} className={'empty-list'}>
//                                     해당하는 주문이 없습니다.
//                                 </td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>
//             </div>
//             <OrderPageBar
//                 page={page}
//                 totalCount={totalCount}
//                 perPage={6}
//                 setPage={setPage}
//             />
//         </div>
//     )
    return null;
}
