export const carrierNameMapping: Record<string, string> = {
    'kr.epost': '우체국',
    'kr.cjlogistics': 'CJ 물류',
    'kr.lotte': '롯데',
    'kr.hanjin': '한진',
    'kr.logen': '로젠',
    'kr.kdexp': '경동',
    'kr.cvsnet': 'GS 우편함',
}

export const bankNames = [
    'KEB 하나',
    'KB국민',
    '신한',
    '우리',
    '농협',
    '씨티',
    '스탠다드차타드',
    'HSBC',
    '기업',
    'SC제일',
    '삼성',
    'KDB산업',
    '부산',
    '대구',
    '제주',
    '신협',
    '기타',
]

// 라벨-값 형식으로 변환
export const bankOptions = bankNames.map((name) => ({
    label: name,
    value: name,
}))

export const carrierOptions = Object.entries(carrierNameMapping).map(([key, value]) => ({
    label: value,
    value: key,
}));


interface StateOption {
    Insert: string[];
    // 다른 키들 추가 가능
}

export const stateOptions = (type: keyof StateOption) => {
    return Object.entries(StateOption[type]).map(([key, value]) => ({
        label: value,
        value: value,
    }));
};



// 요청 가능 상태 목록
namespace AbleStatuses {
    export const cancelReq = ['입금대기', '결재완료']
    export const returnReq = ['배송완료', '반품반려', '교환반려', '교환완료']
    export const exchangeReq = ['배송완료', '교환반려', '반품반려', '교환완료']

    // 상태변경 => 준비중 배송중 배송완료
    export const orderStatus = [
        '입금대기',
        '결제완료',
        '준비중',
        '배송중',
        '배송완료',
        '취소반려',
    ]

    // 상태변경 => 취소
    export const cancelStatus = [
        '입금대기',
        '결제완료',
        '준비중',
        '배송중',
        '배송완료',
    ]

    // 상태변경 => 반품
    export const returnStatus = [
        '입금대기',
        '결제완료',
        '준비중',
        '배송중',
        '배송완료',
    ]

    // 상태변경 => 교환
    export const exchangeStatus = [
        '입금대기',
        '결제완료',
        '준비중',
        '배송중',
        '배송완료',
    ]
}


const  StateOption = {
    Insert :[
        '배송중',
        '배송완료',
    ]
}
